<template>
    <section class="mt-16">  
      <v-breadcrumbs
        v-if="hasComponent == false"
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text" v-if="hasComponent == false">
        <v-icon color="primary">mdi-account-hard-hat</v-icon> <span>Gestão de Autos de Medição</span>
      </h1>

      <div style="position:relative">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16"
          locale="pt-pt"
        >
          <template v-slot:item.work="{item}">
            {{ item.work.code }} - {{ item.work.description }}
          </template>  

          <template v-slot:item.invoiced="{item}">
            {{ getInvoiced(item) }}%
          </template>  

          <template v-slot:item.total="{item}">
            {{ $currency(item.total)}}
          </template>    

          <template v-slot:item.actions="{item}">
            <IconView
              class="mr-2"
              @click="viewWorkMeasurement(item)"
            />
          </template>
          
        </v-data-table>
      </div>
    </section>
  </template>
  <script>
  import IconView from '@/components/ui/IconView.vue';
  import WorkMeasurements from "@/api/WorkMeasurements.js";
  
  export default {
    components: {
      IconView
    },
    props:{
      hasComponent:{
        typeof: Boolean,
        default: false
      },
      work_id:{
        typeof: Boolean,
        default: null
      }
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchWorkMeasurements()}, 10);
        },
        deep: true,
      },
    },
    data: () => ({
      headers: [
        {
          text: 'Obra',
          sortable: true,
          value: 'work',
        },
        {
          text: 'Faturado (%)',
          sortable: true,
          value: 'invoiced',
        },
        {
          text: 'Data',
          sortable: true,
          value: 'data',
        },
        {
          text: 'Total (€)',
          sortable: true,
          value: 'total',
        },
        { 
          text: 'Opções', 
          value: 'actions', 
          sortable: false 
        },
      ],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      filter: {
        work_id: null
      },
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "work_measurements.read"])) {
        this.$router.push('/admin');
      }
  
      this.fillBaseData();
    },
    methods:{
      getInvoiced(item) {
        let custo_obra = item.work.budgeted;

        let custo_cobrado = 0;
        item.lines.forEach(element => {
            custo_cobrado += element.total_price;
        });

        // Evitar divisão por zero
        if (custo_obra === 0) {
            return 0;
        }

        // Calcular a percentagem cobrada
        let percentagem_cobrada = (custo_cobrado / custo_obra) * 100;

        return percentagem_cobrada.toFixed(2); // Retorna com 2 casas decimais
      },
      fillBaseData() {
  
        if(localStorage["work-measurements-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["work-measurements-" + this.$root.session.id]);
  
          this.filter = data.filter;

          if(this.work_id != null)
            this.filter.work_id = this.work_id

          if(this.$route.name == 'Autos-Medicao')
            this.filter.work_id = null
  
          this.options = data.options;
        }
      },
      searchWorkMeasurements(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["work-measurements-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        WorkMeasurements.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetWorkMeasurements()
      {
        this.filter = {
        };
  
        this.searchWorkMeasurements();
      },
      viewWorkMeasurement(item){
        this.$router.push('/admin/works-settings/work-measurements/update/' + item.id);
      }
    },
    computed:{
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Gestão de Autos de Medição',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  