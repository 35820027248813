<template>
    <v-container fluid>
       <v-progress-linear v-if="loading"
          indeterminate          
        ></v-progress-linear>
        <v-row v-if="!loading">
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            v-model="machine_model"
                            :items="getMachines"
                            item-value="id"
                            :item-text="item => item.code + ' - ' + item.name"
                            dense outlined clearable
                            label="Máquinas"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn :disabled="machine_model == null" @click="insertMachine" class="success" rounded>Inserir</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <validation-observer ref="form" v-slot="{  }">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    Código
                                </th>
                                <th class="text-left">
                                    Máquina
                                </th>
                                <th class="text-left" width="20%" v-if="fields.work_fronts.length > 0">
                                    Frente Obra
                                </th>
                                <th class="text-left">
                                    Tipo
                                </th>
                                <th class="text-left">
                                    Opções
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in computeFieldsMachines"
                                    :key="item.code"
                                >
                                    <td>{{ item.code }}</td>
                                    <td>{{ item.name }}</td>                                
                                    <td v-if="fields.work_fronts.length > 0">
                                        <validation-provider v-slot="{ errors }" vid="Frente Obra" name="Frente Obra" rules="required">
                                            <v-autocomplete
                                                :items="fields.work_fronts"
                                                item-value="id"
                                                :error-messages="errors"
                                                clearable
                                                class="mt-4"
                                                :item-text="item => item.reference + ' - ' + item.description"
                                                label="Frente obra"
                                                dense outlined
                                                v-model="item.work_front_id"
                                            />
                                        </validation-provider>
                                    </td>
                                    <td>
                                        {{item.external == true ? 'Externa' : 'Interna'}}
                                    </td>
                                    <td>
                                        <IconRemove
                                            class="mr-2"
                                            @on-submit="deleteMachine(item)"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </validation-observer>
            </v-col>
        </v-row>
      <SuccessSnackBar v-model="successSnack.show" :message="successSnack.message"/>
    </v-container>
</template>
<script>
//import Work from '@/api/Work.js'
import Machine from '@/api/Machine.js'
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';
import IconRemove from '@/components/ui/IconRemove.vue';

export default{
    name:"WorkMachines",
    components:{
        SuccessSnackBar,
        IconRemove
    },
    props: {
        fields: Object,
        method: String
    },
    data(){
        return{
            loading: true,
            successSnack:{
                show: false,
                message: ''
            },
            machine_model: null,
            machines: [],
            machines_list: [],
            remove_machines: []
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        fillBaseData(){
            this.machines_list = this.fields.machines
            Machine.list().then((resp)   =>  {
               this.machines = resp.data 
               this.loading= false
            });
        },
        deleteMachine(item){
            let i = 0; let pos = -1;
            this.machines_list.forEach(element => {
                if(element.id == item.id)
                    pos = i 
                
                i++
            });
            

            if(pos != -1){
                this.remove_machines.push(this.machines_list[pos])
                this.machines_list.splice(pos, 1)
            }

            this.$emit('update-machines', this.machines_list, this.remove_machines)
            
        },
        insertMachine(){
            let ob = null
            
            this.getMachines.forEach(element => {
                if(element.id == this.machine_model)
                    ob = element
            });

            if(ob != null)
                this.machines_list.push(ob)
        },
    },
    computed:{
        computeFieldsMachines(){
            return this.machines_list
        },
        getMachines(){
            let auxiliar = []

            
            this.machines.forEach(w => {
                let exists = false
                this.machines_list.forEach(wa => {
                    if(wa.id == w.id)
                        exists = true
                });

                if(exists == false)
                    auxiliar.push(w)
            });

            return auxiliar
        }
    }
}
</script>