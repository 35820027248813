<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <!-- Botão para abrir a câmara -->
        <v-btn icon @click="openCamera('environment')" v-if="!isCameraActive">
          <v-icon>mdi-camera</v-icon>
        </v-btn>

        <!-- Botões para alternar câmaras -->
        <v-btn icon @click="switchCamera" v-if="isCameraActive">
          <v-icon v-if="activeCamera === 'environment'">mdi-camera-front</v-icon>
          <v-icon v-else>mdi-camera-rear</v-icon>
        </v-btn>

        <!-- Botão para fechar a câmara -->
        <v-btn icon @click="stopCamera" v-if="isCameraActive">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <canvas ref="canvas" style="display:none;"></canvas>
        <video ref="camera" autoplay playsinline></video>
      </v-col>
      <v-col cols="12">
        <p v-if="qrCodeData">QR Code: {{ qrCodeData }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import jsQR from "jsqr";

export default {
  name: "ReadQRCode",
  data() {
    return {
      qrCodeData: null,
      currentStream: null, // Guarda a stream ativa
      activeCamera: null, // Indica a câmara ativa (traseira ou frontal)
      isCameraActive: false, // Indica se a câmara está ativa
    };
  },
  methods: {
    async openCamera(facingMode = "environment") {
      try {
        // Fecha a câmara ativa se já existir uma
        if (this.currentStream) {
          this.stopCamera();
        }

        const constraints = {
          video: {
            facingMode: { exact: facingMode },
          },
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.currentStream = stream; // Guarda a stream ativa
        this.$refs.camera.srcObject = stream;
        this.activeCamera = facingMode; // Define a câmara ativa
        this.isCameraActive = true;
        this.scanQRCode();
      } catch (error) {
        console.error("Erro ao acessar a câmara:", error);
      }
    },
    switchCamera() {
      // Alterna entre a câmara traseira e a frontal
      const nextCamera = this.activeCamera === "environment" ? "user" : "environment";
      this.openCamera(nextCamera);
    },
    stopCamera() {
      if (this.currentStream) {
        const tracks = this.currentStream.getTracks();
        tracks.forEach((track) => track.stop()); // Para todas as tracks
        this.currentStream = null;
      }
      this.activeCamera = null;
      this.isCameraActive = false;
    },
    scanQRCode() {
      const video = this.$refs.camera;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");

      video.addEventListener("play", () => {
        const scan = () => {
          if (video.paused || video.ended) return;

          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);

          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          const code = jsQR(imageData.data, imageData.width, imageData.height);

          if (code) {
            this.qrCodeData = code.data;
            window.location.href = this.qrCodeData;
          } else {
            requestAnimationFrame(scan);
          }
        };
        requestAnimationFrame(scan);
      });
    },
  },
};
</script>

<style>
video {
  width: 100%; /* Ocupa toda a largura */
  height: auto; /* Mantém a proporção */
  object-fit: cover; /* Garante que o vídeo preencha o espaço sem distorção */
}
</style>
