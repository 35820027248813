<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-account-hard-hat</v-icon> <span>Gestão de Unidades de Medida</span>
      </h1>
      <v-icon color="primary">
        mdi-eye
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Visualizar

      <v-icon color="red" class="ml-4">
        mdi-delete
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Remover

      <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Pesquisar</span>
            </v-tooltip>      
            <v-tooltip right color="success">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="success"
                    large
                    @click="exportUnitsList"
                  >
                    <v-icon>mdi-paperclip</v-icon>
                  </v-btn>
                </template>
                <span>Exportar</span>
              </v-tooltip>      
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  @click="openNewModalUnit()"
                  v-if="$root.session.hasPermission(['super', 'units_management.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Registar</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16"
          locale="pt-pt"
        >

          <template v-slot:item.created_at="{item}">
            {{getTableDate(item.created_at)}}
          </template>

          <template v-slot:item.updated_at="{item}">
            {{getTableDate(item.updated_at)}}
          </template>

          <template v-slot:item.actions="{item}">
            <IconView
              class="mr-2"
              @click="viewUnit(item)"
            />
            <IconRemove
              v-if="$root.session.hasPermission(['super', 'units_management.delete'])"
              class="mr-2"
                :reverse="filter.deleted_worker"
              @on-submit="deleteUnit(item)"
            />
          </template>
        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetUnits">
              Limpar Campos
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchUnits">

            <v-list-item>
              <v-text-field
                v-model="filter.abbreviation"
                prepend-inner-icon="mdi-barcode"
                label="Abreviatura"
                clearable
              ></v-text-field>
            </v-list-item>
            
            <v-list-item>
              <v-text-field
                v-model="filter.description"
                prepend-inner-icon="mdi-form-textbox"
                label="Descrição"
                clearable
              ></v-text-field>
            </v-list-item>


            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              Pesquisar
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>

      <v-dialog
          v-model="addUnit"
          max-width="800px"
        >
          <AddUnit 
            v-if="addUnit"
            :editUnit="unit_selected"
            @close="addUnit = false"
            @close-update="fillBaseData()"
          />
        </v-dialog>
      
  </section>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import Category from "@/api/Category.js";
import UnitsManagement from "@/api/UnitsManagement.js";
import AddUnit from '@/components/units_management/Add.vue';
import dayjs from 'dayjs';
import Export from '@/api/Export.js'

export default {
  components: {
    IconRemove,
    IconView,
    AddUnit
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchUnits()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "GPD - Gestão de Unidades de Medida";

    Category.list().then(({data}) => {
      this.categories = data;
    });
  },
  data: () => ({
    addUnit: false,
    exporting: false,
    headers: [
    {
          text: 'Abreviatura',
          sortable: true,
          value: 'abbreviation',
        },
        {
          text: 'Descrição',
          sortable: true,
          value: 'description',
        },
        {
          text: 'Criada a',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Última modificação a',
          sortable: true,
          value: 'updated_at',
        },
        {
          text: 'Opções',
          value: 'actions', 
          width: '200px',
          sortable: false 
        },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      abbreviation: null,
      description: null
    },
    categories: [],
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "units_management.read"])) {
      this.$router.push('/no-permission');
    }

    this.fillBaseData();
  },
  methods:{    
    openNewModalUnit(){
      this.unit_selected = null
      this.addUnit = true
    },
    exportUnitsList(){
        let data = 'units_management';
        Export.generalExport(data)
          .then((resp) => {            
              window.open(resp.data.url, '_blank')
              Export.delete( resp.data.path)
              .then(() => {
              });
          });
      },
    getTableDate(date) {
      return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
    },
    fillBaseData() {

      if(localStorage["units-measurement-new-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["units-measurement-new-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchUnits(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["units-measurement-new-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      UnitsManagement.search(request).then(response => {

        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetUnits()
    {
      this.filter = {
      };

      this.searchUnits();
    },
    viewUnit(item){
        this.unit_selected = item
        this.addUnit = true
    },
    deleteUnit(item){
        UnitsManagement.delete(item.id)
          .then(() => {
            this.searchUnits();
          });
      },
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão de Unidades de Medida',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
