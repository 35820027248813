import { render, staticRenderFns } from "./WorkerTraining.vue?vue&type=template&id=36b1e7d1&scoped=true"
import script from "./WorkerTraining.vue?vue&type=script&lang=js"
export * from "./WorkerTraining.vue?vue&type=script&lang=js"
import style0 from "./WorkerTraining.vue?vue&type=style&index=0&id=36b1e7d1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b1e7d1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VDivider,VIcon})
