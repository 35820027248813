<template>
    <v-row>
        <v-col cols="12">
            <h2 class="text-h6 font-weight-bold">
            Máquinas
            </h2>
            <v-divider></v-divider>
        </v-col>     
        <v-col cols="12">   
        <v-simple-table dense class="d-none d-lg-block">
            <template v-slot:default>
            <thead class="primary">
                <tr>
                    <th class="text-center white--text rounded-pill rounded-r-0" style="width: 35%;">
                        Nome
                    </th>
                    <th class="text-center white--text">
                        Código
                    </th>
                    <th class="text-center white--text">
                        Diesel
                    </th>
                    <th class="text-center white--text">
                        Ad Blue
                    </th>
                    <th class="text-center white--text">
                        Horas/KM Inicio
                    </th>
                    <th class="text-center white--text">
                        Horas/KM Fim
                    </th>
                    <th class="text-center white--text">
                        Parado à Ordem de
                    </th>
                    <th class="text-center white--text rounded-pill rounded-l-0">
                        Opções
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="machine in template_fields.machines" :key="machine.id">
                <td class="text-center">
                    <validation-provider v-slot="{ errors }" vid="machine" name="Máquina" rules="required">
                    <v-combobox
                        v-model="machine.object"
                        label="Máquina *"
                        :items="machines"
                        dense outlined
                        class="mt-4"
                        :item-text="item => item.code + ' - ' + item.name"
                        item-value="id"
                        hide-selected
                        :error-messages="errors"
                        @input="machine.code = $event.code; machine.id = $event.id; machine.name = $event.name; machine.started_hours = $event.next_start"
                    >
                    </v-combobox>
                    </validation-provider>
                </td>
                <td class="text-center">
                    {{ machine.code }}
                </td>
                <td class="text-center">
                    <validation-provider v-slot="{ errors }" vid="diesel" name="Diesel" rules="required">
                    <v-text-field
                        v-model="machine.diesel"
                        dense outlined
                        class="mt-4"
                        label="Diesel *"
                        :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                </td>
                <td class="text-center">
                    <validation-provider v-slot="{ errors }" vid="ad_blue" name="Ad Blue" rules="required">
                    <v-text-field
                        v-model="machine.ad_blue"
                        dense outlined
                        class="mt-4"
                        label="Ad Blue *"
                        :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                </td>
                <td class="text-center">
                    <validation-provider v-slot="{ errors }" vid="diesel" name="Horas/KM" rules="required">
                    <v-text-field
                        v-model="machine.started_hours"
                        dense outlined
                        class="mt-4"
                        label="Horas/KM Início*"
                        :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                </td>
                <td class="text-center">
                    <validation-provider v-slot="{ errors }" vid="diesel" name="Horas/KM" rules="required">
                    <v-text-field
                        v-model="machine.finished_hours"
                        dense outlined
                        class="mt-4"
                        label="Horas/KM Fim*"
                        :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                </td>
                <td class="text-center">
                    <v-text-field
                    v-model="machine.stopped_reason"
                    dense outlined
                    class="mt-4"
                    label="Razão"
                    ></v-text-field>
                </td>
                <td class="text-center"> 
                    <v-row>
                    <v-col cols="12" md="6">
                        <v-btn
                        dark
                        small
                        color="secondary"
                        @click="error.show = true"
                        >
                        <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn
                        dark
                        small
                        color="error"
                        @click="removeMachine(machine)"
                        >
                        <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                    </v-col>
                    </v-row>
                </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        <v-data-iterator
            :items="template_fields.machines"
            item-key="id"
            :items-per-page="-1"
            hide-default-footer
            class="d-block d-lg-none"
        >
            <template v-slot:default="{ items }">
            <v-row>
                <v-col
                v-for="machine in items"
                :key="machine.id + '10000'"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                >
                <v-card>
                    <v-list
                    dense
                    >
                    <v-list-item>
                        <v-list-item-content>Nome:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <validation-provider v-slot="{ errors }" vid="machine" name="Máquina" rules="required">
                            <v-combobox
                            v-model="machine.object"
                            label="Máquina *"
                            :items="machines"
                            :item-text="item => item.name"
                            item-value="id"
                            hide-selected
                            :error-messages="errors"
                            @input="machine.code = $event.code; machine.id = $event.id; machine.name = $event.name"
                            >
                            </v-combobox>
                        </validation-provider>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Código:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        {{ machine.code }}
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Diesel:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <validation-provider v-slot="{ errors }" vid="diesel" name="Diesel" rules="required">
                            <v-text-field
                            v-model="machine.diesel"
                            label="Diesel *"
                            :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Ad Blue:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <validation-provider v-slot="{ errors }" vid="ad_blue" name="Ad Blue" rules="required">
                            <v-text-field
                            v-model="machine.ad_blue"
                            label="Ad Blue *"
                            :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Horas/KM Início:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <validation-provider v-slot="{ errors }" vid="diesel" name="Horas/KM" rules="required">
                            <v-text-field
                            v-model="machine.started_hours"
                            label="Horas/KM Início*"
                            :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Horas/KM Início:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <validation-provider v-slot="{ errors }" vid="diesel" name="Horas/KM" rules="required">
                            <v-text-field
                            v-model="machine.finished_hours"
                            label="Horas/KM Fim*"
                            :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                        </v-list-item-content>
                    </v-list-item>                                
                    <v-list-item>
                        <v-list-item-content>Parado à Ordem de:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <v-text-field
                            v-model="machine.stopped_reason"
                            label="Razão"
                        ></v-text-field>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="true">
                        <v-list-item-content>Opções:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <v-row>
                            <v-col cols="12" md="6">
                            <v-btn
                                dark
                                small
                                block
                                color="secondary"
                                @click="error.show = true"
                            >
                                <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                            </v-btn>
                            </v-col>
                            <v-col cols="12" md="6">
                            <v-btn
                                dark
                                small
                                block
                                color="error"
                                @click="removeMachine(machine)"
                            >
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                            </v-col>
                        </v-row>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list>
                </v-card>
                </v-col>
            </v-row>
            </template>
        </v-data-iterator>
        <v-row justify="center">
            <v-btn
            fab
            dark
            small
            color="primary"
            class="mt-7"
            @click="addMachine"
            >
            <v-icon small>mdi-plus</v-icon>
            </v-btn>
        </v-row>
    </v-col>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
        <DialogConfirmation 
            v-if="openConfirmationDialog" 
            title="Remover Item" 
            type="warning" 
            :opened="openConfirmationDialog" 
            @on-submit="submitRemove" 
            @on-cancel="openConfirmationDialog = false">
                Tem a certeza que pretende remover o item?
        </DialogConfirmation>
    </v-row>
</template>
<script>
import dayjs from 'dayjs'
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';

export default {
    components:{
        DialogConfirmation,
        ErrorSnackbar
    },
    props: {
        template_fields: Object,
        machines: Array
    },
    data: () => ({
        openConfirmationDialog: false,
        error:{
            show: false,
            message: 'Ação indisponível em template'
        },
        toSend:{
            id: null
        }
    }),
    mounted(){
        
    },
    methods:{
        addMachine() {
            this.template_fields.machines.push({
                id: dayjs().unix(),
                name: "",
                code: "-",
                diesel: 0,
                ad_blue: 0,
                started_hours: 0,
                finished_hours: 0,
                stopped_reason: "",
                worked_hours: []
            });
        },
        removeMachine(machineObject) {
            this.toSend.id = machineObject.id
            this.openConfirmationDialog = true
        },
        async submitRemove(){
            await this.$emit('remove', this.toSend.id)
            this.openConfirmationDialog = false
        }
    }
}
</script>