<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-shape</v-icon> <span>Registo de Categoria</span>
      </h1>

      <div class="mt-16">
        <category method="create"></category>
      </div>
  </section>
</template>
<script>
import Category from '@/components/categories/Category'

export default {
  components: {
    Category
  },
  beforeCreate(){
    document.title = "GPD - Registo de Categorias";
  },
  data: () => ({
    
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "categories.create"])) {
      this.$router.push('/no-permission');
    }

  },
  methods:{
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão Categorias',
          disabled: false,
          to: '/admin/workers-settings/categories',
          exact: true,
        },
        {
          text: 'Registo de Categoria',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
