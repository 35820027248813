<template>
    <div>
        <v-container>
            <v-row>

                <v-col cols="12" md="4">
                    <v-select
                        v-model="selectedYear"
                        :items="years"
                        label="Selecione o Ano"
                        prepend-icon="mdi-calendar"
                        @change="onYearSelected"
                        dense outlined
                    ></v-select>
                </v-col>

            </v-row>
            <v-row>
                <v-col>
                    <v-data-table :headers="headers" :items="monthlyScores" class="elevation-1">
                        <template v-slot:item.year>
                            {{ selectedYear }}
                        </template>
                        <template v-slot:item.month="{ item }">
                            {{ item.month }}
                        </template>
                        <template v-slot:item.score="{ item }">
                            {{ item.score }} %
                        </template>
                    </v-data-table>


                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Score from '@/api/Score';
import dayjs from 'dayjs';

export default {
    props: {
        worker_id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            menu: false,
            selectedYear: new dayjs().year().toString(),
            years: this.getYears(),
            headers: [
                { text: 'Year', value: 'year' },
                { text: 'Month', value: 'month' },
                { text: 'Score', value: 'score' },
            ],
            monthlyScores: [],
        };
    },
    watch: {
        selectedYear(newYear) {
            this.userMonthlyScores(newYear);
        },
    },
    methods: {
        getYears() {
            const currentYear = new Date().getFullYear();
            const years = [];
            for (let year = 2023; year <= currentYear; year++) {
                years.push(year.toString());
            }
            return years;
        },

        onYearSelected() {
            this.userMonthlyScores(this.selectedYear);
        },
        
        userMonthlyScores(year) {
            Score.getMonthlyScores(this.worker_id, year)
                .then((response) => {
                    this.monthlyScores = Array.isArray(response.data) ? response.data : [];
                })
                .catch((error) => {
                    console.error('Erro ao obter os scores mensais:', error);
                    this.monthlyScores = [];
                });
        },
    },
    created() {
        this.userMonthlyScores(this.selectedYear);
    },
};
</script>

