<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-abacus</v-icon> <span>Registo de Artigo</span>
        </h1>
  
        <div class="mt-16">
          <Article method="create"></Article>
        </div>
    </section>
  </template>
  <script>
  import Article from '@/components/articles/Article'
  
  export default {
    components: {
        Article
    },
    beforeCreate(){
      document.title = "GPD - Registo de Artigos";
    },
    data: () => ({
      
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "articles.create"])) {
        this.$router.push('/no-permission');
      }
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Gestão de Artigos',
            disabled: false,
            to: '/admin/works-settings/articles',
            exact: true,
          },
          {
            text: 'Registo de Artigos',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  