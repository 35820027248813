<template>
  <div class="worker-training">
    <div class="header">
        <h2>Formações do Colaborador  |  </h2>
        
        <v-btn icon @click="openModal">
            <v-icon class="hover-icon">mdi-plus</v-icon>
        </v-btn>
        <TrainingModal
        :show="showModal"
        :training="currentTraining"
        :isEdit="isEdit"
        :worker_id="worker.id"
        @close="closeModal"
        @save="saveTraining"
        @training-saved="fetchTrainings"
        />
    </div>
    <v-divider></v-divider>
    <div class="training-list">
      <div v-if="worker.trainings.length === 0" class="no-trainings">
        <p>Não há formações disponíveis.</p>
      </div>
      <div v-else>
        <div v-for="training in worker.trainings" :key="training.id" :class="['training-card', training.status ? 'active' : 'expired']">
            <div class="badge" :class="training.status ? 'badge-active' : 'badge-expired'">
                {{ training.status ? 'Activo' : 'Expirado' }}
            </div>
            <h3>{{ training.title }}</h3>
          <div class="training-dates">
            <p>Data: {{ training.start_date }}</p>
            <p v-if="training.validity">|   Validade: {{ training.validity }}</p>
          </div>
          <p>{{ training.description }}</p>
          <div class="card-actions">
          <v-btn icon @click="editTraining(training)">
              <v-icon class="hover-icon" color="yellow">mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="deleteTraining(training.id)">
              <v-icon class="hover-icon" color="red">mdi-delete</v-icon>
            </v-btn>
        </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TrainingModal from './TrainingModal.vue';
import Worker from '@/api/Worker.js';

export default {
  components: {
    TrainingModal
  },
    props: {
        worker: {
        type: Object,
        required: true
        }
    },

  data() {
    return {
      trainings: [],
      showModal: false,
      isEdit: false,
      currentTraining: {
        title: '',
        startDate: '',
        lifetime: true,
        validity: '',
        description: ''
      }
    };
  },


  created() {
    this.fetchTrainings();
  },


  methods: {
    async fetchTrainings() {
      try {
        const response = await Worker.find(this.worker.id);
        this.worker.trainings = response.data.trainings;
      } catch (error) {
        console.error('Erro ao buscar formações:', error);
      }
    },

    openModal() {
      this.isEdit = false;
      this.currentTraining = { title: '', startDate: '', lifetime: true, validity: '', description: '' };
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },

    async saveTraining(training) {
        console.log("ESTOU AQUI");
      try {
        if (this.isEdit) {
          await Worker.updateTraining(training.id, training);
        } else {
          await Worker.training(training);
        }
        this.fetchTrainings();
        this.closeModal();
      } catch (error) {
        console.error('Erro ao salvar formação:', error);
      }
    },

    editTraining(training) {
      this.isEdit = true;
      this.currentTraining = { ...training, startDate: training.start_date };
      this.showModal = true;
    },

    async deleteTraining(trainingId) {
      try {
        console.log('Apaga id : ', trainingId);
        await Worker.trainingDelete(trainingId);
        this.fetchTrainings();
      } catch (error) {
        console.error('Erro ao apagar formação:', error);
      }
    }
  }
};
</script>

<style scoped>
.worker-training {
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
}

.header h2 {
  margin-right: 10px;
}

.vertical-divider {
  width: 1px;
  height: 24px;
  background-color: #000;
  margin-right: 10px;
}

.training-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.no-trainings {
  text-align: center;
  color: #888;
}

.training-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  width: 100%; /* Ajuste a largura conforme necessário */
  max-width: 1000px; /* Limite máximo de largura */
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff; 
}

.training-card.active {
  background-color: #e0f7e9; /* Fundo verde claro */
}

.training-card.expired {
  background-color: #ffe0e0; /* Fundo laranja claro */
}

.training-dates {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.card-actions {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}

.badge {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
}

.badge-active {
  background-color: #4caf50; /* Verde */
}

.badge-expired {
  background-color: #f44336; /* Vermelho */
}

.hover-icon {
  transition: color 0.3s;
}

.hover-icon:hover {
  color: #828991; /* Cor ao passar o cursor */
}
</style>