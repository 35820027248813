<template>
    <v-container fluid class="ma-0 pa-0" style="overflow: hidden;">
        <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
        ></v-progress-linear>
        <v-card class="ma-0 pa-0" v-if="!loading">
                <v-card-title class="primary white--text">
                    Detalhes da diária
                    <v-spacer></v-spacer>
                    <v-btn icon @click="$emit('close')"><v-icon color="white">mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-row class="mt-4" no-gutters>
                    <v-col cols="12" md="6">
                        <v-card-text>
                            <v-text-field dense outlined label="Obra" :value="diary.work_object.name" readonly />
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="6" v-if="diary.work_front != null">
                        <v-card-text>
                            <v-text-field dense outlined label="Frente" :value="diary.work_front.reference" readonly />
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="4" v-if="diary.work_object.client != null">
                        <v-card-text>
                            <v-text-field dense outlined label="Cliente" :value="diary.work_object.client.name" readonly />
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="3" v-if="false">
                        <v-card-text>
                            <v-text-field dense outlined label="Estado" :value="diary.work_object.status" readonly />
                        </v-card-text>
                    </v-col>
                    <v-col cols="12">
                        <v-card-text>
                            <v-textarea readonly label="Descrição" :value="diary.work_object.description" dense outlined/>
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-card-title class="primary white--text">
                    Colaboradores
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Colaborador
                                    </th>
                                    <th class="text-left">
                                        Horas de Início
                                    </th>
                                    <th class="text-left">
                                        Horas de fim
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-for="worker in diary.workers" :key="worker.id">
                                <tr>
                                    <td>
                                        {{worker.code}} - {{ worker.name }}
                                    </td>
                                    <td>
                                        {{ fixTime(worker.started_hour) }}:{{ fixTime(worker.started_minute) }}
                                    </td>
                                    <td>
                                        {{ fixTime(worker.finished_hour) }}:{{ fixTime(worker.finished_minute) }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-title class="primary white--text">
                    Máquinas
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Máquina
                                    </th>
                                    <th class="text-left">
                                        Horas de início
                                    </th>
                                    <th class="text-left">
                                        Horas de fim
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-for="machine in diary.machines" :key="machine.id">
                                <tr>
                                    <td>
                                        {{machine.code }} - {{ machine.name }}
                                    </td>
                                    <td>
                                        <span v-if="machine.counter_type != 'SEM_CONTADOR'">{{ machine.started_hours }}</span>
                                        <span v-else>-</span>
                                    </td>
                                    <td>
                                        <span v-if="machine.counter_type != 'SEM_CONTADOR'">{{ machine.finished_hours }}</span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-title class="primary white--text">
                    Unidades de obra
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Unidade
                                    </th>
                                    <th class="text-left">
                                        Unit
                                    </th>
                                    <th class="text-left">
                                        Medição
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-for="work_unit in diary.work_units" :key="work_unit.id">
                                <tr>
                                    <td>
                                        {{work_unit.code}} - {{ work_unit.name }}
                                    </td>
                                    <td>
                                        {{ work_unit.unit }}
                                    </td>
                                    <td>
                                        {{ work_unit.measure }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import DiaryApi from "@/api/Diary.js";

export default {
    components: {
    },
    props:{
        id: String
    },
    data: () => ({
        diary: {},
        loading:true,
        reloadAllComponent: 0
    }),
    mounted(){

        if(!this.$root.session.hasPermission(["super", "diaries.read"])) {
        this.$router.push('/no-permission');
        }

        DiaryApi.find(this.id).then(({data}) => { this.diary = data; this.loading = false;});
    },
    methods:{
        fixTime(t){
            if(t < 10)
                return '0' + t

            return t
        }
    }
};
</script>
