<template>
    <section class="mt-16">
      <v-row>        
        <v-col cols="12" md="3" v-if="$root.session.worker != null">
          <StyledCard title="Dias de férias" :text="$root.session.worker.vacation_days" icon="mdi-beach" />
        </v-col>
        <v-col cols="12">
            <WorkerCalendar />
        </v-col>
      </v-row>
    </section>
  </template>
  
  <script>
  import StyledCard from '@/components/ui/StyledCard.vue'
  import WorkerCalendar from '@/components/dashboard/internals/WorkerCalendar.vue'
  
  export default {
    name: "ColaboradorView",
    components:{
      WorkerCalendar,
      StyledCard
    },
    data() {
      return {
        
      };
    },
  };
  </script>
  