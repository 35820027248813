<template>
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ isEdit ? 'Editar Formação' : 'Adicionar Formação' }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field v-model="training.title" label="Nome da Formação" required></v-text-field>
            <v-menu
              ref="startDateMenu"
              v-model="startDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="training.startDate"
                  label="Data de Início"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="training.startDate" @input="startDateMenu = false"></v-date-picker>
            </v-menu>
            <v-checkbox
              v-model="training.lifetime"
              label="Formação Vitalícia"
            ></v-checkbox>
            <v-menu
              v-if="!training.lifetime"
              ref="validityDateMenu"
              v-model="validityDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="training.validity"
                  label="Validade do Certificado"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="training.validity" @input="validityDateMenu = false"></v-date-picker>
            </v-menu>
            <v-textarea v-model="training.description" label="Descrição" required></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">Cancelar</v-btn>
          <v-btn color="primary" text @click="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import Worker from '@/api/Worker.js';
  
  export default {
    props: {
      show: Boolean,
      training: Object,
      isEdit: Boolean,
      worker_id: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        startDateMenu: false,
        validityDateMenu: false
      };
    },
    methods: {
      close() {
        this.$emit('close');
      },
      async save() {
        if (this.$refs.form.validate()) {
          try {
            const response = await Worker.training({
              id: this.training.id || null,
              worker_id: this.worker_id,
              title: this.training.title,
              startDate: this.training.startDate,
              validity: this.training.validity,
              description: this.training.description
            });
            this.$emit('save', response.data);
            this.$emit('training-saved');
            this.close();
          } catch (e) {
            console.error("Erro a guardar formação", e);
          }
        }
      }
    }
  };
  </script>
  
  <style scoped>
  </style>