<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" md="4">
                        <v-card class="elevation-0" outlined height="200px">
                            <v-card-title class="primary white--text">
                                Faturado (%)
                            </v-card-title>
                            <v-card-text class="text-center mt-4">
                                <v-progress-circular
                                    :rotate="360"
                                    :size="100"
                                    :width="15"
                                    :value="work.invoiced"
                                    color="teal"
                                    >
                                    {{work.invoiced}}%
                                </v-progress-circular>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-card class="elevation-0" outlined height="200px">
                            <v-card-title class="primary white--text">
                                Estado
                                <v-spacer></v-spacer>
                                <v-btn class="success"
                                    :to="'/admin/works-settings/work-measurements/create/' + $route.params.id" >
                                    <v-icon>mdi-plus</v-icon>Auto de Medição
                                </v-btn>
                            </v-card-title>
                            <v-card-text class="mt-4">
                                Sem informação
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-tabs background-color="primary" dark v-model="tab" fixed-tabs>
                    <v-tab>Artigos</v-tab>
                    <v-tab>Dados da Obra</v-tab>
                    <v-tab>Autos</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item :key="1">
                        <validation-observer ref="form" v-slot="{ invalid }">
                            <v-row>
                                <v-col cols="12">
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Artigo
                                                    </th>
                                                    <th class="text-left">
                                                        Quantidade
                                                    </th>
                                                    <th class="text-left">
                                                        Preço Uni. (€)
                                                    </th>
                                                    <th class="text-left">
                                                        Total (€)
                                                    </th>
                                                    <th class="text-left">
                                                        Op.
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="artigo in fields.artigos_obra" :key="artigo.id">
                                                <td style="width: 50%;" class="mx-4 px-4">
                                                    <validation-provider v-slot="{ errors }" vid="Artigo" name="Artigo" rules="required">
                                                        <v-autocomplete
                                                            v-model="artigo.article_id"
                                                            :items="artigos"
                                                            label="Artigo *"
                                                            :error-messages="errors"
                                                            dense outlined class="mt-4"
                                                            :item-text="item => item.code + ' - ' + item.description"
                                                            item-value="id"
                                                        />
                                                    </validation-provider>
                                                </td>
                                                <td class="mx-4 px-4">
                                                    <validation-provider v-slot="{ errors }" vid="Quantidade" name="Quantidade" rules="required">
                                                        <v-text-field
                                                            v-model="artigo.qty"
                                                            dense outlined class="mt-4"
                                                            label="Quantidade *"
                                                            :error-messages="errors"
                                                        />
                                                    </validation-provider>
                                                </td>
                                                <td class="mx-4 px-4">
                                                    <validation-provider v-slot="{ errors }" vid="Preço" name="Preço" rules="required">
                                                        <v-text-field
                                                            :value="getItemValue(artigo)"
                                                            dense outlined class="mt-4"
                                                            readonly
                                                            :error-messages="errors"
                                                            label="Preço (€)"
                                                        />
                                                    </validation-provider>
                                                </td>
                                                <td class="mx-4 px-4">
                                                    <validation-provider v-slot="{ errors }" vid="Total" name="Total" rules="required">
                                                        <v-text-field
                                                            :value="getItemTotalValue(artigo)"
                                                            readonly
                                                            :error-messages="errors"
                                                            dense outlined class="mt-4"
                                                            label="Total (€)"
                                                        />
                                                    </validation-provider>
                                                </td>
                                                <td class="mx-4 px-4">
                                                    <IconRemove
                                                        class="mr-2"
                                                        @on-submit="deleteLine(artigo)"
                                                    />
                                                </td>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                                <v-col cols="12" align="center">
                                    <v-divider></v-divider>
                                    <v-btn :disabled="invalid" fab class="success mt-4" @click="addLine">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" align="center">
                                    <v-btn @click="submit" :disabled="fields.artigos_obra.length == 0 || invalid" class="success">
                                        Gravar
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </validation-observer>
                    </v-tab-item>
                    <v-tab-item :key="2">
                        <DiaryArticles :work_id="$route.params.id"/>
                    </v-tab-item>
                    <v-tab-item :key="3">
                        <WorksMeasurements :hasComponent="true" :work_id="$route.params.id"/>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Artigo gravado com sucesso!
        </DialogSuccess>
    </v-container>
</template>
<script>
import ArticleAPI from '@/api/Article.js'
import dayjs from 'dayjs'
import IconRemove from '@/components/ui/IconRemove.vue';
import DiaryArticles from '@/components/works/autos/diary_articles/DiaryArticles.vue'
import WorksMeasurements from '@/views/works_measurements/WorksMeasurements.vue'
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default{
    name: "ArtigosObra",
    props:{
        work: Object
    },
    components:{
        IconRemove,
        WorksMeasurements,
        DiaryArticles,
        DialogSuccess
    },
    data(){
        return{
            tab: 0,
            artigos:[],
            fields:{
                artigos_obra: []
            },
            success: false,
            loading: true,
            error:{
                title: null,
                message: null
            }
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        onSuccess(){
            this.success = false
        },
        deleteLine(item){
            let i = 0; let pos = -1;
            this.fields.artigos_obra.forEach(element => {
                if(element.id == item.id)
                    pos = i 
                
                i++
            });
            

            if(pos != -1){
                this.fields.artigos_obra.splice(pos, 1)
            }
        },
        submit() {
            this.loading = true
            ArticleAPI.setArtigosObra(this.$route.params.id, this.fields).then(() => {

                this.success = true;

                this.loading = false;

            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);

                    return;
                }

                this.error.title = "Erro " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        getItemTotalValue(item){
            let preco = 0
            this.artigos.forEach(element => {
                if(element.id == item.article_id)
                    preco = element.unit_price
            });

            return preco * item.qty
        },
        getItemValue(item){
            let preco = 0
            this.artigos.forEach(element => {
                if(element.id == item.article_id)
                    preco = element.unit_price
            });

            return preco
        },
        addLine(){
            this.fields.artigos_obra.push({
                id: dayjs().unix() + '-new',
                article_id: null,
                qty: 0, 
                unit_price: null
            })
        },
        async fillBaseData(){
            //Route::name("admin.works.articles.create")->post("/", "CreateArticle");
            await ArticleAPI.getArtigosObra(this.$route.params.id).then((resp)    =>  {
                if(resp.data != '')
                    this.fields = resp.data
            })

            await ArticleAPI.list().then((resp)   =>  {
                this.artigos = resp.data
            })

            this.loading = false
        }
    }
}
</script>