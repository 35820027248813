<template>
  <v-card>
    <v-card-title class="primary white--text">Avisos de máquinas</v-card-title>
    <v-card-text>
      <div style="position:relative">
      
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16"
          locale="pt-pt"
        >
          <template v-slot:item.machine="{item}">
            <IconView
              class="mr-2 ml-2 mt-4"
              @click="openMachine(item.machine_id)"
            />
            {{ item.machine_code }} - {{item.machine_name}}
          </template>    
          
          <template v-slot:item.diary_id="{item}">
            <IconView
              class="mr-2 ml-2 mt-4"
              @click="openDiary(item.diary_id)"
            />{{item.diary.date}}
          </template>   
          
          <template v-slot:item.explanation="{item}">
            <div v-html="item.explanation"></div>
          </template>    


        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        
      </v-navigation-drawer>
    </v-card-text>
  </v-card>
</template>
<script>
import Machine from "@/api/Machine.js";
import IconView from '@/components/ui/IconView.vue';

export default {
  components:{
    IconView
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchWarnings()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "GPD - Avisos de Máquinas";
  },
  data: () => ({
    headers: [
      {
        text: 'Diária',
        sortable: true,
        value: 'diary_id',
        width: '18%'
      },
      {
        text: 'Máquina',
        sortable: true,
        value: 'machine',
      },
      {
        text: 'Aviso',
        sortable: true,
        value: 'warning',
      },
      {
        text: 'Descrição',
        sortable: true,
        value: 'explanation',
      },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      
    },
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "warnings.read"])) {
      this.$router.push('/no-permission');
    }

    this.fillBaseData();
  },
  methods:{
    openDiary(id){
      window.open(process.env.VUE_APP_DOMAIN_FULL + 'admin/diaries/' + id, '_blank')
    },
    openMachine(id){
      window.open(process.env.VUE_APP_DOMAIN_FULL + 'admin/machines/' + id, '_blank')
    },
    fillBaseData() {

      if(localStorage["machines-warnings-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["machines-warnings-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchWarnings(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["machines-warnings-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Machine.getMachineWarnings(request).then(response => {

        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetWarnings()
    {
      this.filter = {
      };

      this.searchMachines();
    },
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Avisos de máquinas',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
