<template>
    <div class="container403">
      <v-icon class="icon-container" large color="red">mdi-block-helper</v-icon>
      <h1 class="title">Acesso Negado</h1>
      <p class="message">
        Parece que não tens permissões para aceder a este conteúdo. <br />
        Por favor, entra em contacto com o departamento de IT se necessitas de acesso.
      </p>
      <a href="/admin" class="home-link">Voltar à Página Inicial</a>
    </div>
  </template>
  
  <script>
  export default {
    data: () => ({}),
  };
  </script>
  
  <style scoped>
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    background-color: #f9f9f9;
    font-family: "Roboto", sans-serif;
  }
  
  .container403 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    padding: 20px;
    background-color: #ffffff;
  }
  
  .icon-container {
    font-size: 8rem;
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 2.5rem;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .message {
    font-size: 1.2rem;
    color: #555555;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .home-link {
    font-size: 1rem;
    color: #ffffff;
    background-color: #4caf50;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .home-link:hover {
    background-color: #45a049;
  }
  </style>
  