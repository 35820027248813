a<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-abacus</v-icon> <span>Edição de Auto de Medição</span>
        </h1>
  
        <div class="mt-16">
          <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
  
          <template v-if="loading">
            <v-row>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-skeleton-loader
                  type="button"
                ></v-skeleton-loader>
            </v-row>
          </template>
          <WorkMeasurement :hasComponent="true" v-else method="update" @reload="reload" :measure="measure"></WorkMeasurement>
        </div>
    </section>
  </template>
  <script>
  import WorkMeasurement from '@/components/work_measurements/WorkMeasurement'
  import WorkMeasurementsAPI from "@/api/WorkMeasurements.js";
  
  export default {
    components: {
        WorkMeasurement
    },
    beforeCreate(){
      document.title = "GPD - Edição de Autos de Medição";
    },
    data: () => ({
        measure: {},
        loading:true
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "work_measurements.update", "work_measurements.read"])) {
        this.$router.push('/admin');
      }
  
      WorkMeasurementsAPI.find(this.$route.params.id).then(({data}) => { this.measure = data; this.loading = false;});
    },
    methods:{
      reload(){
        WorkMeasurementsAPI.find(this.$route.params.id).then(({data}) => { this.measure = data; this.loading = false;});
      }
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Gestão de Autos de Medição',
            disabled: false,
            to: '/admin/works-settings/articles',
            exact: true,
          },
          {
            text: 'Edição de Autos de Medição',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  