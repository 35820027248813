import BaseApi from "./BaseApi";

export default class Templates extends BaseApi{
    construct() {}
 
    static async create(params, id) {
        return await Templates.getAxiosInstance().post(Templates.getApiUrl() + id, params);
    }

    static async removeMaoObra(line, id){
        return await Templates.getAxiosInstance().delete(Templates.getApiUrl() + 'remove/maoobra/' + line + '/' + id);
    }

    static async removeMaquina(line, id){
        return await Templates.getAxiosInstance().delete(Templates.getApiUrl() + 'remove/maquina/' + line + '/' + id);
    }

    static async removeUnidade(line, id){
        return await Templates.getAxiosInstance().delete(Templates.getApiUrl() + 'remove/unidade/' + line + '/' + id);
    }

    static async find(params) {
        return await Templates.getAxiosInstance().get(Templates.getApiUrl() + params.work_id, {params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/templates/";
    }

}