<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>

        <h1 class="primary--text">
          <v-icon color="primary">mdi-qrcode</v-icon> <span>QR Code</span>
        </h1>

        <div v-if="!loading">
            <ReadQRCode v-if="$root.session.hasRole(['colaborador'])" />
            <ComponentQRCode v-else :works="works" />
        </div>

    </section>
</template>
<script>
import Work from '@/api/Work.js'
import ComponentQRCode from '@/components/qrcode/CreateQRCode.vue'
import ReadQRCode from '@/components/qrcode/ReadQRCode.vue'

export default{
    name: "QRCode",
    components:{
        ComponentQRCode,
        ReadQRCode
    },
    data(){
        return{
            loading: true,
            works: []
        }
    },
    mounted(){
      if(!this.$root.session.hasRole(["super", "encarregado", "colaborador"])){
          this.$router.push('/admin');
      }

      
        this.fillBaseData();
    },
    methods:{
        async fillBaseData(){
            await Work.list({ status: "STARTED" }).then(({ data }) => {
                this.works = data;
            });
            this.loading = false
        }
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'QR Code',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
}
</script>