<template>
    <v-container fluid>
       <v-progress-linear v-if="loading"
          indeterminate
        ></v-progress-linear>
        <v-row v-if="!loading">
          <v-col cols="12">
            <v-alert
              border="left"
              type="info"
              elevation="2"
              outlined
              dense
              class="alert-custom"
            >
              <template v-slot:prepend>
                <v-icon color="info">mdi-file-document-outline</v-icon>
              </template>
              Defina um template a ser usado em todas as Partes Diárias relacionadas com esta obra.
            </v-alert>
          </v-col>
          <v-col cols="12" v-if="fields.work_fronts.length > 0">
            <v-alert              
              border="left"
              type="warning"
              elevation="2"
              outlined
              dense
              class="alert-custom"
            >
              <template v-slot:prepend>
                <v-icon color="warning">mdi-alert</v-icon>
              </template>
              Caso não escolha uma frente de obra, o template poderá ser utilizado por defeito em todas as frentes que não tenham template associado
            </v-alert>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              :value="fields.code + ' - ' + fields.name"
              dense outlined
              label="Obra"
              readonly
            />
          </v-col>
          <v-col cols="12" md="3" v-if="fields.work_fronts.length > 0">
            <v-autocomplete
              :items="fields.work_fronts"
              :item-text="item => item.reference + ' - ' + item.description"
              item-value="id"
              clearable
              label="Frente de Obra"
              dense outlined
              v-model="template_fields.work_front_id"
              @change="getTemplate"
            />
          </v-col>
        </v-row>
        <v-row v-if="canShow">
          <v-col cols="12">
            <TemplateMaoObra
              :key="reloadMaoObra"
              :template_fields="template_fields"
              :workers="workers"
              return-object
              @remove="removeMaoObra"
            />
          </v-col>
          <v-col cols="12">
            <TemplateMaquina 
              :template_fields="template_fields"
              :machines="machines"
              return-object
              @remove="removeMaquina"
            />
          </v-col>
          <v-col cols="12">
            <TemplateUnidades
              :template_fields="template_fields"
              :units="units"
              return-object
              @remove="removeUnidade"
            />
          </v-col>
        </v-row>
        <v-row v-if="!loading" class="mt-8" align="center" justify="space-around">
          <v-btn depressed color="primary" @click="saveTemplate">
            Gravar
          </v-btn>
        </v-row>
    </v-container>
</template>
<script>
import Templates from '@/api/Templates.js'
import TemplateMaoObra from '@/components/works/templates/childs/TemplateMaoObra'
import TemplateMaquina from '@/components/works/templates/childs/TemplateMaquina'
import TemplateUnidades from '@/components/works/templates/childs/TemplateUnidades'

export default{
    name: "WorkTemplate",
    components:{
      TemplateMaoObra,
      TemplateMaquina,
      TemplateUnidades
    },
    props:{
      fields: Object,
      workers: Array,
      units: Array,
      machines: Array
    },
    data(){
        return{
          reloadMaoObra: 0,
          loading: true,
          template_fields: {
              workers: [],
              machines: [],
              units: [],
              work_front_id: null,
              work_id: null
          },
          success: false
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
      async saveTemplate(){
        this.loading = true
        this.template_fields.work_id = this.$route.params.id
        await Templates.create(this.template_fields, this.fields.id).then(() => {

          this.success = true;

          this.loading = false;

          }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);

              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
      },
      async fillBaseData(){
        this.loading = true

        this.getTemplate()
      },        
      getTemplate(){        
        this.loading = true

        let params = {
            work_id: this.$route.params.id,
            work_front_id: this.template_fields.work_front_id
        }

        let parent = this
        Templates.find(params).then((resp)  =>  {
            this.template_fields = resp.data
            this.template_fields.workers = resp.data.workers
            this.template_fields.machines = resp.data.machines
            this.template_fields.units = resp.data.units

            this.template_fields.workers.forEach(a1 => {
              parent.workers.forEach(a2 => {
                if(a1.worker_id == a2.id)
                  a1.object = a2
              });
            });

            this.template_fields.machines.forEach(a1 => {
              parent.machines.forEach(a2 => {
                if(a1.machine_id == a2.id)
                  a1.object = a2
              });

            });
            
            this.template_fields.units.forEach(a1 => {
              parent.units.forEach(a2 => {
                if(a1.unit_id == a2.id)
                  a1.object = a2
              });
            });
              
            this.loading = false
          })
      },
      removeMaoObra(line_id){
        if(this.fields.template == null){
          let index = this.template_fields.workers.findIndex(item => item.id == line_id);

          this.template_fields.workers.splice(index, 1)

          this.$emit('update-object')

          this.loading = false;
          return
        }
        this.loading = true
        Templates.removeMaoObra(line_id, this.fields.template.id).then(()  =>  {

          let index = this.template_fields.workers.findIndex(item => item.id == line_id);

          this.template_fields.workers.splice(index, 1)

          this.$emit('update-object')

          this.loading = false;
        })
      },
      removeMaquina(line_id){
        if(this.fields.template == null){
          let index = this.template_fields.machines.findIndex(item => item.id == line_id);

          this.template_fields.machines.splice(index, 1)

          this.$emit('update-object')

          this.loading = false;
          return
        }
        this.loading = true
        Templates.removeMaquina(line_id, this.fields.template.id).then(()  =>  {

          let index = this.template_fields.machines.findIndex(item => item.id == line_id);

          this.template_fields.machines.splice(index, 1)

          this.$emit('update-object')

          this.loading = false;
        })
      },
      removeUnidade(line_id){
        if(this.fields.template == null){
          let index = this.template_fields.units.findIndex(item => item.id == line_id);

          this.template_fields.units.splice(index, 1)

          this.loading = false;
          return
        }
        this.loading = true
        Templates.removeUnidade(line_id, this.fields.id).then(()  =>  {

          let index = this.template_fields.units.findIndex(item => item.id == line_id);

          this.template_fields.units.splice(index, 1)

          this.loading = false;
        })
      },
    },
    computed:{
      canShow(){
        if(this.fields.work_fronts.length == 0)
          return true

        if(this.template_fields.work_front_id != null)
          return true

        return false
      }
    }
}
</script>