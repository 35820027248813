<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-caravan</v-icon> <span>Pedidos de férias</span>
        </h1>
  
        <div class="mt-16">
          <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
  
          <template v-if="loading">
            <v-row>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-skeleton-loader
                  type="button"
                ></v-skeleton-loader>
            </v-row>
          </template>
          <Holiday v-else method="update" :holiday="holiday"></Holiday>
        </div>
    </section>
  </template>
  <script>
  import HolidayAPI from "@/api/Holidays.js";
  import Holiday from '@/components/holidays/Holiday'
  
  export default {
    components: {
        Holiday
    },
    beforeCreate(){
      document.title = "GPD - Pedidos de férias";
    },
    data: () => ({
        holiday: {},
        loading:true
    }),
    mounted(){
  
      if(this.$root.session.worker_id == null) {
        this.$router.push('/admin');
      }
  
      HolidayAPI.find(this.$route.params.id).then(({data}) => { this.holiday = data; console.log(data); this.loading = false;});
    },
    methods:{
      
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Pedidos de férias',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
};
</script>
  