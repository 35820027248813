import BaseApi from "./BaseApi";

export default class Article extends BaseApi{
    construct() {}

    static async search(params) {
        return await Article.getAxiosInstance().get(Article.getApiUrl(),{params: params});
    }

    static async create(params) {
        return await Article.getAxiosInstance().post(Article.getApiUrl(),params);
    }

    static async update(params, id) {
        return await Article.getAxiosInstance().put(Article.getApiUrl() + id,params);
    }

    static async delete(id) {
        return await Article.getAxiosInstance().delete(Article.getApiUrl() + id);
    }

    static async find(id) {
        return await Article.getAxiosInstance().get(Article.getApiUrl() + id);
    }

    static async list(params) {
        let url = Article.getApiUrl() + "list";

        return Article.getAxiosInstance().get(url,{params: params});
    }

    static async getArtigosObra(obra_id){
        let url = Article.getApiUrl() + 'get-artigos-obra/' + obra_id
        
        return Article.getAxiosInstance().get(url)
    }

    static async setArtigosObra(obra_id, params){
        let url = Article.getApiUrl() + 'set-artigos-obra/' + obra_id
        
        return Article.getAxiosInstance().post(url, params)
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/articles/";
    }
}