<template>
    <validation-observer ref="form" v-slot="{ invalid }">
      <v-form @submit.prevent="submit"  class="mb-16">
         <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
        <fieldset :disabled="loading">
          <v-row>
            <v-col cols="12" md="3">
              <validation-provider v-slot="{ errors }" vid="Código" name="Código" rules="required">
                <v-text-field
                  dense outlined
                  v-model="fields.code"
                  prepend-inner-icon="mdi-account-details"
                  label="Código *"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" vid="Descrição" name="Descrição" rules="required">
                <v-text-field
                  dense outlined
                  v-model="fields.description"
                  prepend-inner-icon="mdi-account-details"
                  label="Descrição *"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="3">
              <validation-provider v-slot="{ errors }" vid="Preço Unitário" name="Preço Unitário" rules="required">
                <v-currency-field
                  dense outlined
                  v-model="fields.unit_price"
                  prepend-inner-icon="mdi-currency-eur"
                  label="Preço Unitário *"
                  :error-messages="errors"
                ></v-currency-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-btn :disabled="invalid" depressed color="success" type="submit">
              Gravar
            </v-btn>
          </v-row>
        </fieldset>
      </v-form>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
        Artigo gravado com sucesso!
      </DialogSuccess>
    </validation-observer>
  </template>
  <script>
  import Article from "@/api/Article.js";
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  
  export default {
    components: {
      DialogSuccess
    },
    props: {
     article: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
    article: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
  
          this.fields.id = data.id;
        }
      },
    },
    beforeCreate(){
    },
    data: () => ({
      fields: {
        id: null,
        code: null,
        description: null,
        unit_price: 0
      },
      loading:false,
      error: {
        title: '',
        message: '',
      },
      success: false,
    }),
    mounted(){
      this.fillBaseData();
    },
    methods:{
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        this.$router.push('/admin/works-settings/articles');
      },
      fillBaseData(){

      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          let data = {...this.fields};
  
          Article[this.method](data, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;
  
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = "Erro " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    }
  };
  </script>
  