var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-16"},[_c('v-breadcrumbs',{staticClass:"pl-0",attrs:{"large":"","items":_vm.crumbs,"divider":"/"}}),_c('h1',{staticClass:"primary--text"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-abacus")]),_vm._v(" "),_c('span',[_vm._v("Gestão de Artigos")])],1),_c('div',{staticStyle:{"position":"relative"}},[_c('v-container',[_c('v-speed-dial',{attrs:{"absolute":"","top":"","right":"","direction":"bottom","open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","x-large":"","fab":""},model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},'v-btn',attrs,false),on),[(_vm.speedDial)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('span',[_vm._v("Opções")])])]},proxy:true}]),model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},[_c('v-tooltip',{attrs:{"right":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"info","large":""},on:{"click":function($event){_vm.searching = true;}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}])},[_c('span',[_vm._v("Pesquisar")])]),_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$root.session.hasPermission(['super', 'articles.create']))?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"secondary","large":"","to":"/admin/works-settings/articles/create"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Registar")])])],1)],1),_c('v-data-table',{staticClass:"mt-16",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.unit_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$currency(item.unit_price))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('IconView',{staticClass:"mr-2",on:{"click":function($event){return _vm.viewArticle(item)}}}),(!item.has_actions && _vm.$root.session.hasPermission(['super', 'articles.delete']))?_c('IconRemove',{staticClass:"mr-2",on:{"on-submit":function($event){return _vm.deleteArticle(item)}}}):_vm._e()]}}])})],1),_c('v-navigation-drawer',{staticClass:"pt-6",attrs:{"fixed":"","right":"","hide-overlay":"","width":"520"},model:{value:(_vm.searching),callback:function ($$v) {_vm.searching=$$v},expression:"searching"}},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"fab":"","small":"","color":"secondary"},on:{"click":function($event){_vm.searching = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"absolute":"","right":"","small":"","color":"error"},on:{"click":_vm.resetArticles}},[_vm._v(" Limpar Campos ")])],1)],1),_c('v-list',{staticClass:"pt-10"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchArticles.apply(null, arguments)}}},[_c('v-row',{staticClass:"mt-10",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v(" Pesquisar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }