<template>
    <v-row v-if="!loading">
        <v-col cols="12" md="12">
            <h2 class="text-h6 font-weight-bold">
            Mão de Obra
            </h2>
            <v-divider></v-divider>
        </v-col>        
        <v-col cols="12">
            <v-simple-table dense class="d-none d-lg-block">
                <template v-slot:default>
                <thead class="primary">
                    <tr>
                    <th class="text-center white--text rounded-pill rounded-r-0">
                        Nome
                    </th>
                    <th class="text-center white--text">
                        Horário
                    </th>
                    <th class="text-center white--text">
                        Pernoita
                    </th>
                    <th class="text-center white--text">
                        Trabalho Noturno
                    </th>
                    <th class="text-center white--text">
                        Almoço
                    </th>
                    <th class="text-center white--text rounded-pill rounded-l-0">
                        Opções
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="worker in template_fields.workers" :key="worker.id">
                        <td class="text-center">
                            <validation-provider v-slot="{ errors }" vid="laborer" name="Colaborador" rules="required">
                            <v-combobox
                                class="mt-4"
                                v-model="worker.object"
                                label="Nome *"                  
                                :items="workers"
                                dense outlined
                                :item-text="item => item.name"
                                autocomplete="off"
                                item-value="id"
                                hide-selected
                                :error-messages="errors"
                                clearable
                                @input="worker.name = $event.name; worker.id = $event.id"
                            >
                            </v-combobox>
                            </validation-provider>
                        </td>
                        <td class="text-center">
                            <v-row>
                            <v-col cols="12" md="6">
                                <v-row>
                                <v-col cols="12" md="6">
                                    <validation-provider v-slot="{ errors }" vid="hour" name="Hora" rules="required">
                                    <v-text-field
                                        v-model="worker.started_hour"
                                        class="mt-4"
                                        label="Hora Entrada *"
                                        dense outlined
                                        :error-messages="errors"
                                    ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <validation-provider v-slot="{ errors }" vid="minute" name="Minuto" rules="required">
                                    <v-text-field
                                        v-model="worker.started_minute"
                                        label="Minuto Entrada *"
                                        class="mt-4"
                                        dense outlined
                                        :error-messages="errors"
                                    ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-row>
                                <v-col cols="12" md="6">
                                    <validation-provider v-slot="{ errors }" vid="hour" name="Hora" rules="required">
                                    <v-text-field
                                        v-model="worker.finished_hour"
                                        label="Hora Saída *"
                                        dense outlined
                                        class="mt-4"
                                        :error-messages="errors"
                                    ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <validation-provider v-slot="{ errors }" vid="minute" name="Minuto" rules="required">
                                    <v-text-field
                                        v-model="worker.finished_minute"
                                        label="Minuto Saída *"
                                        class="mt-4"
                                        dense outlined
                                        :error-messages="errors"
                                    ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                </v-row>
                            </v-col>
                            </v-row>
                        </td>
                        <td class="text-center">
                            <v-simple-checkbox  
                                v-model="worker.slept_night"
                            />
                        </td>
                        <td class="text-center">
                            <v-simple-checkbox                     
                                v-model="worker.night_work"
                            />
                        </td>
                        <td class="text-center">
                            <v-simple-checkbox                     
                                v-model="worker.lunch"
                            />
                        </td>
                        <td class="text-center">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-btn
                                    dark
                                    small
                                    color="secondary"
                                    @click="error.show = true"
                                    >
                                    <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-btn
                                    dark
                                    small
                                    color="error"
                                    @click="removeWorker(worker)"
                                    >
                                    <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
            <v-data-iterator
                :items="template_fields.workers"
                item-key="id"
                :items-per-page="-1"
                hide-default-footer
                class="d-block d-lg-none"
            >
                <template v-slot:default="{ items }">
                <v-row>
                    <v-col
                    v-for="worker in items"
                    :key="worker.id + '10000'"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    >
                    <v-card>
                        <v-list
                        dense
                        >
                        <v-list-item>
                            <v-list-item-content>Nome:</v-list-item-content>
                            <v-list-item-content class="align-end">
                            <validation-provider v-slot="{ errors }" vid="laborer" name="Colaborador" rules="required">
                                <v-combobox
                                v-model="worker.object"
                                label="Nome *"                              
                                :items="workers"
                                :item-text="item => item.name"
                                item-value="id"
                                hide-selected
                                :error-messages="errors"
                                clearable
                                @input="worker.name = $event.name; worker.id = $event.id"
                                >
                                </v-combobox>
                            </validation-provider>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>Horário:</v-list-item-content>
                            <v-list-item-content class="align-end">
                            <v-row>
                                <v-col cols="12" lg="6">
                                <v-row>
                                    <v-col cols="12" lg="6">
                                    <validation-provider v-slot="{ errors }" vid="hour" name="Hora" rules="required">
                                        <v-text-field
                                        v-model="worker.started_hour"
                                        label="Hora Entrada *"
                                        :error-messages="errors"
                                        ></v-text-field>
                                    </validation-provider>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                    <validation-provider v-slot="{ errors }" vid="minute" name="Minuto" rules="required">
                                        <v-text-field
                                        v-model="worker.started_minute"
                                        label="Minuto Entrada *"
                                        :error-messages="errors"
                                        ></v-text-field>
                                    </validation-provider>
                                    </v-col>
                                </v-row>
                                </v-col>
                                <v-col cols="12" lg="6">
                                <v-row>
                                    <v-col cols="12" lg="6">
                                    <validation-provider v-slot="{ errors }" vid="hour" name="Hora" rules="required">
                                        <v-text-field
                                        v-model="worker.finished_hour"
                                        label="Hora Saída *"
                                        :error-messages="errors"
                                        ></v-text-field>
                                    </validation-provider>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                    <validation-provider v-slot="{ errors }" vid="minute" name="Minuto" rules="required">
                                        <v-text-field
                                        v-model="worker.finished_minute"
                                        label="Minuto Saída *"
                                        :error-messages="errors"
                                        ></v-text-field>
                                    </validation-provider>
                                    </v-col>
                                </v-row>
                                </v-col>
                            </v-row>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>Pernoita:</v-list-item-content>
                            <v-list-item-content class="align-end">
                            <v-simple-checkbox 
                                :disabled="true"
                                v-model="worker.slept_night"
                            />
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>Trabalho Noturno:</v-list-item-content>
                            <v-list-item-content class="align-end">
                            <v-simple-checkbox 
                                :disabled="true"
                                v-model="worker.night_work"
                            />
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>Almoço:</v-list-item-content>
                            <v-list-item-content class="align-end">
                            <v-simple-checkbox 
                                :disabled="true"
                                v-model="worker.lunch"
                            />
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="true">
                            <v-list-item-content>Opções:</v-list-item-content>
                            <v-list-item-content class="align-end">
                            <v-row>
                                <v-col cols="12" md="6">
                                <v-btn
                                    dark
                                    small
                                    block
                                    color="secondary"
                                    @click="error.show = true"
                                >
                                    <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                                </v-btn>
                                </v-col>
                                <v-col cols="12" md="6">
                                <v-btn
                                    dark
                                    small
                                    block
                                    color="error"
                                    @click="removeWorker(worker)"
                                >
                                    <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                                </v-col>
                            </v-row>
                            </v-list-item-content>
                        </v-list-item>
                        </v-list>
                    </v-card>
                    </v-col>
                </v-row>
                </template>
            </v-data-iterator>
            <v-row justify="center" v-if="true">
                <v-btn
                fab
                dark
                small
                color="primary"
                class="mt-7"
                @click="addWorker"
                >
                <v-icon small>mdi-plus</v-icon>
                </v-btn>
            </v-row>
        </v-col>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
        <DialogConfirmation 
            v-if="openConfirmationDialog" 
            title="Remover Item" 
            type="warning" 
            :opened="openConfirmationDialog" 
            @on-submit="submitRemove" 
            @on-cancel="openConfirmationDialog = false">
                Tem a certeza que pretende remover o item?
        </DialogConfirmation>
    </v-row>
</template>
<script>

import dayjs from 'dayjs'
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';

export default {
    components:{
        DialogConfirmation,
        ErrorSnackbar
    },
    props: {
        template_fields: Object,
        workers: Array,
    },
    data: () => ({
        loading: true,
        openConfirmationDialog: false,
        error:{
            show: false,
            message: 'Ação indisponível em template'
        },
        toSend:{
            id: null
        }
    }),
    mounted(){
        this.template_fields.workers.forEach(element => {
            element.night_work = !!element.night_work
            element.lunch = !!element.lunch
            element.slept_night = !!element.slept_night
        });
        this.loading = false
    },
    methods:{
        addWorker() {
            this.template_fields.workers.push({
                id: dayjs().unix(),
                name: "",
                started_hour: 8,
                started_minute: 0,
                finished_hour: 16,
                finished_minute: 0,
                slept_night: false,
                night_work: false,
                lunch: true,
                worked_hours: []
            });
        },
        removeWorker(workerObject) {            
            this.toSend.id = workerObject.id
            this.openConfirmationDialog = true
        },
        async submitRemove(){
            await this.$emit('remove', this.toSend.id)
            this.openConfirmationDialog = false
        }
    }
}
</script>