<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-shape</v-icon> <span>Edição de Categoria</span>
      </h1>

      <div class="mt-16">
        <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>

        <template v-if="loading">
          <v-row>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-skeleton-loader
                type="button"
              ></v-skeleton-loader>
          </v-row>
        </template>
        <category v-else method="update" :category="category"></category>
      </div>
  </section>
</template>
<script>
import CategoryApi from "@/api/Category.js";
import Category from '../../../components/categories/Category.vue';

export default {
  components: {
    Category
  },
  beforeCreate(){
    document.title = "GPD - Edição de Categorias";
  },
  data: () => ({
      category: {},
      loading:true
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "categories.update"])) {
      this.$router.push('/no-permission');
    }

    CategoryApi.find(this.$route.params.id).then(({data}) => { this.category = data; this.loading = false;});
  },
  methods:{
    
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão Categorias',
          disabled: false,
          to: '/admin/workers-settings/categories',
          exact: true,
        },
        {
          text: 'Edição de Categoria',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
