import BaseApi from "./BaseApi";

export default class Score extends BaseApi{
    construct() {}

    static async search(params) {
        return await Score.getAxiosInstance().get(Score.getApiUrl(),{params: params});
    }

    static async getMonthlyScores(worker_id, year) {
        return await Score.getAxiosInstance().get(`${Score.getApiUrl()}monthlyuser/${worker_id}/${year}`);
    }

    static async getScores(year, month = null) {
        let url = `${Score.getApiUrl()}year/${year}/month/`;
        if (month !== null) {
            url += `${month}`;
        }
        return await Score.getAxiosInstance().get(url);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/scores/";
    }
}