<template>
    <v-container fluid>
       <v-progress-linear v-if="loading"
          indeterminate          
        ></v-progress-linear>
        <v-row v-if="!loading">
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            v-model="worker_model"
                            :items="getWorkers"
                            item-value="id"
                            :item-text="item => item.code + ' - ' + item.name"
                            dense outlined clearable
                            label="Colaboradores"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn :disabled="worker_model == null" @click="insertWorker" class="success" rounded>Inserir</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <validation-observer ref="form" v-slot="{  }">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    Código
                                </th>
                                <th class="text-left">
                                    Nome
                                </th>
                                <th class="text-left" width="20%" v-if="fields.work_fronts.length > 0">
                                    Frente Obra
                                </th>
                                <th class="text-center">
                                    Tipo
                                </th>
                                <th class="text-center">
                                    Opções
                                </th>
                                </tr>
                            </thead>
                                <tbody>
                                    <tr
                                        v-for="item in computeFieldsWorkers"
                                        :key="item.code"
                                    >
                                        <td>{{ item.code }}</td>
                                        <td>{{ item.name }}</td>
                                        <td v-if="fields.work_fronts.length > 0">
                                            <validation-provider v-slot="{ errors }" vid="Frente Obra" name="Frente Obra" rules="required">
                                                <v-autocomplete
                                                    :items="fields.work_fronts"
                                                    item-value="id"
                                                    clearable
                                                    class="mt-4"
                                                    :item-text="item => item.reference + ' - ' + item.description"
                                                    label="Frente obra *"
                                                    :error-messages="errors"
                                                    dense outlined
                                                    v-model="item.work_front_id"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td class="text-center">
                                            {{item.company == null ? 'Interno' : 'Externo'}}
                                        </td>
                                        <td class="text-center">
                                            <IconRemove
                                                class="mr-2"
                                                @on-submit="deleteWorker(item)"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                        </template>
                    </v-simple-table>
                </validation-observer>
            </v-col>
        </v-row>
      <SuccessSnackBar v-model="successSnack.show" :message="successSnack.message"/>
    </v-container>
</template>
<script>
//import Work from '@/api/Work.js'
import Worker from '@/api/Worker.js'
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';
import IconRemove from '@/components/ui/IconRemove.vue';

export default{
    name:"WorkWorkers",
    components:{
        SuccessSnackBar,
        IconRemove
    },
    props: {
        fields: Object,
        method: String
    },
    data(){
        return{
            loading: true,
            successSnack:{
                show: false,
                message: ''
            },
            worker_model: null,
            workers: [],
            workers_list: [],
            remove_workers: []
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        fillBaseData(){
            this.workers_list = this.fields.workers
            Worker.list().then((resp)   =>  {
               this.workers = resp.data 
               this.loading= false
            });
        },
        deleteWorker(item){
            let i = 0; let pos = -1;
            this.workers_list.forEach(element => {
                if(element.id == item.id)
                    pos = i 
                
                i++
            });
            

            if(pos != -1){
                this.remove_workers.push(this.workers_list[pos])
                this.workers_list.splice(pos, 1)
            }

            this.$emit('update-workers', this.workers_list, this.remove_workers)
            
        },
        insertWorker(){
            let ob = null
            
            this.getWorkers.forEach(element => {
                if(element.id == this.worker_model)
                    ob = element
            });

            if(ob != null)
                this.workers_list.push(ob)
        },
    },
    computed:{
        computeFieldsWorkers(){
            return this.workers_list
        },
        getWorkers(){
            let auxiliar = []

            
            this.workers.forEach(w => {
                let exists = false
                this.workers_list.forEach(wa => {
                    if(wa.id == w.id)
                        exists = true
                });

                if(exists == false)
                    auxiliar.push(w)
            });

            return auxiliar
        }
    }
}
</script>