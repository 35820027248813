<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-file-sign</v-icon> <span>Registo de Auto de Medição</span>
        </h1>
  
        <div class="mt-16">
          <WorkMeasurement :hasComponent="true" method="create"></WorkMeasurement>
        </div>
    </section>
  </template>
  <script>
  import WorkMeasurement from '@/components/work_measurements/WorkMeasurement'
  
  export default {
    components: {
        WorkMeasurement
    },
    beforeCreate(){
      document.title = "GPD - Registo de Autos de Medição";
    },
    data: () => ({
      
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "work_measurements.create"])) {
        this.$router.push('/admin');
      }
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Gestão de Autos de Medição',
            disabled: false,
            to: '/admin/works-settings/articles',
            exact: true,
          },
          {
            text: 'Registo de Autos de Medição',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  