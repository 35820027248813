<template>
    <section class="mt-16">
        <v-progress-linear v-if="loading"
        indeterminate
        class="global-loader"
        ></v-progress-linear>

        <v-breadcrumbs
            v-if="this.$root.$vuetify.theme.isDark"
            large
            :items="crumbs"
            divider="/"
            light
            :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
        <v-breadcrumbs
            v-else
            :items="crumbs"
            divider="/"
            light
            :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>

        <h1 class="primary--text">
            <v-icon color="primary">mdi-cursor-default-gesture</v-icon> <span>Gestão Interna</span>
        </h1>

        <v-tabs background-color="primary" dark v-model="tab" fixed-tabs style="margin-top: 3rem;">
            <v-tab>Gestão Interna</v-tab>
            <v-tab>Calendário</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless>
        <v-tab-item>
        <div style="position:relative; margin-top: 4rem; margin-bottom: 4rem;">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="auto">
                            <v-icon color="red">mdi-circle-medium</v-icon>
                            Sem definição
                        </v-col>
                        <v-col cols="12" md="auto">
                            <v-icon color="yellow">mdi-circle-medium</v-icon>
                            Falta definir
                        </v-col>
                        <v-col cols="12" md="auto">
                            <v-icon color="green">mdi-circle-medium</v-icon>
                            Completo
                        </v-col>
                        <v-col cols="12" md="auto">
                            <v-icon color="purple">mdi-circle-medium</v-icon>
                            Feriado
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="5">
                    <v-date-picker
                        id="picker"
                        style="width: 100% !important;"
                        v-model="selectedDate"
                        :event-color="date => date[9] % 2 ? 'red' : 'yellow'"
                        :events="functionEvents"
                        @change="changeDataByCalendar"
                        :picker-date.sync="pickerDate"
                    ></v-date-picker>
                </v-col>
                <v-col cols="12" md="7">
                    <v-expansion-panels>
                        <v-expansion-panel
                        >
                        <v-expansion-panel-header class="primary white--text">
                            Definir utilizadores livres
                            <template v-slot:actions>
                                <v-icon class="icon white--text">$expand</v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card elevation="0">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" md="10">
                                            <v-autocomplete                                         
                                                v-model="userObject"
                                                :items="users"
                                                dense outlined
                                                menu-props="auto"
                                                label="Utilizadores"
                                                item-text="name"
                                                item-value="id"
                                                persistent-hint
                                                prepend-inner-icon="mdi-account"
                                                single-line
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-btn @click="addUser" color="success">
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" md="10">
                                            <v-autocomplete                                         
                                                v-model="workerObject"
                                                :items="workers"
                                                dense outlined
                                                menu-props="auto"
                                                label="Colaboradores"
                                                item-text="name"
                                                item-value="id"
                                                persistent-hint
                                                prepend-inner-icon="mdi-account"
                                                single-line
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-btn @click="addWorker" color="success">
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>                            
                                </v-card-text>
                                <v-card-text>
                                    <v-data-table
                                        :headers="freeHeaders"
                                        :items="freeUsers"
                                        :server-items-length="totalfreeUsers"
                                        :loading="loading"
                                        :footer-props="tableFooter"
                                        :options.sync="options"
                                        locale="pt-pt"
                                    >
                                    <template v-slot:item.code="{item}">
                                        {{ item.worker == null ? '-' : item.worker.code }}
                                    </template>
                                    <template v-slot:item.actions="{item}">
                                        <IconRemove
                                            class="mr-2"
                                            @on-submit="deleteFreeUser(item)"
                                        />
                                    </template>
                                    
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
                <v-col cols="12">
                    <v-card elevation="0">
                        <v-toolbar class="primary white--text">
                            Utilizadores livres
                        </v-toolbar>
                        <v-card-text>
                            <v-card elevation="0">
                                <v-card-text>
                                    <FreeUsersNoDiary ref="free_users" :works="works" v-if="loading == false" @loading="loadCharger" :noUsersData="noUsersData"/>
                                </v-card-text>
                            </v-card>
                        </v-card-text>
                    </v-card>                    
                </v-col>
                <v-col cols="12">
                    <v-card elevation="0">
                        <v-toolbar class="primary white--text">
                            Utilizadores sem diárias
                        </v-toolbar>
                        <v-card-text>
                            <UsersNoDiary ref="users_no_diary" :works="works" @loading="loadCharger" :noUsersData="noUsersData"/>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row no-gutters align="center" justify="center">
                <v-btn @click="gravar" color="primary" class="mt-4">
                    Gravar
                </v-btn>
            </v-row>     
        </div>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Registos gravados com sucesso!
        </DialogSuccess>
    </v-tab-item>
 
        <v-tab-item>
            <Calendar/>
        </v-tab-item>
    </v-tabs-items>

    </section>
</template>
<script>
import DiaryFree from '@/api/DiaryFree';
import Worker from '@/api/Worker';
import User from '@/api/User';
import Faults from '@/api/Faults';
import Diary from '@/api/Diary';
import Work from '@/api/Work';
import APICalendar from '@/api/CalendarEvents.js'
import Configuration from '@/api/Configuration';

import FreeUsersNoDiary from '@/components/internal_admin/FreeUsersNoDiary.vue'
import UsersNoDiary from '@/components/internal_admin/UsersNoDiary.vue'
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import IconRemove from '@/components/ui/IconRemove.vue';
import Calendar from '@/views/internal_admin/calendar/Calendar.vue';

export default{
    name: "InternalAdminV2",
    components:{
        DialogSuccess,
        FreeUsersNoDiary,
        UsersNoDiary,
        IconRemove,
        Calendar
    },
    watch: {
        pickerDate (val) {
            let dt = new Date(val + '-01');
            let today = new Date();

            let month = dt.getMonth() + 1
            let today_month = today.getMonth() + 1

            if(month == today_month){
                this.selectedDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            }else{
                let aux = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)
                aux.setMonth(month-1)
                this.selectedDate = aux.toISOString().substr(0, 10);
            }

            this.fillMonthArray()
        },
        'options': {
            immediate: false,
            handler () {
                setTimeout(() => {this.searchData()}, 10);
            },
            deep: true,
        },
        selectedDate:{
            immediate: true,
            handler(val){
                let dt = new Date(val)
                let month = dt.getMonth() + 1

                if(month != this.monthGuider)
                    this.monthGuider = month
            }
        },
        monthGuider:{
            immediate: true,
            handler(){                
                this.fillMonthArray()
            }
        }
    },
    data(){
        return{
            tab: 0,
            works: [],
            monthGuider: null,
            pickerDate: null,
            monthArray: [],
            companyEvents: [],
            noUsersData: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            panel: [0, 1],
            tableFooter: {
            'items-per-page-options': [5, 10, 15, 20],
            'items-per-page-text': "Linhas por página"
            },
            freeHeaders: [
                {
                    text: 'Código',
                    sortable: true,
                    value: 'code',
                },
                {
                    text: 'Nome',
                    sortable: true,
                    value: 'name',
                },
                { 
                    text: 'Opções', 
                    value: 'actions', 
                    sortable: false 
                },
            ],
            loading: true,
            selectedDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            options: {},
            searching: false,
            freeUsers: [],
            totalfreeUsers: null,
            workers: [],
            users: [],
            success: false,
            workerObject: null,
            userObject: null,
            replacePages: false,
            feriados: []
        }
    },
    async mounted(){
        
        if(!this.$root.session.hasPermission(["super", "holidays_faults.read"])) {
            this.$router.push('/no-permission');
        }
        
        this.loading = true
        await this.fillBaseData()
        await this.changeData()
        this.loading = false
    },
    methods: {
        fillMonthArray(){            
            let data = {
                day: this.selectedDate
            }

            Diary.checkMonth(data).then(response => {

                this.monthArray = response.data

            });
        },
        gravar(){
            let data = {
                'users': this.$refs['users_no_diary'].gravar(),
                'free_users': this.$refs['free_users'].gravar()
            }
            Faults.update(data).then(() => {

                this.success = true;

                this.loading = false;

                }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = "Erro " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        onSuccess(){
            this.success = false
            this.searchData()
        },
        deleteFreeUser(u){
            DiaryFree.delete(u.id)
                .then(() => {
                    this.searchData();
                });
        },
        addUser(){
            this.loading = true;
            let data = {
                type: 'user',
                id: this.userObject
            }
            DiaryFree.create(data).then(() => {

                this.success = true;

                this.userObject = null

                this.loading = false;

                this.searchData()

            })
        },
        addWorker(){
            this.loading = true;
            let data = {
                type: 'worker',
                id: this.workerObject
            }
            DiaryFree.create(data).then(() => {

                this.success = true;

                this.workerObject = null

                this.loading = false;

            })
        },
        async fillBaseData(){
            Configuration.get().then((resp) =>  {
                this.feriados = resp.data.feriados
            })
            await Worker.list().then(({data}) => {
                this.workers = data;
            });
            await User.list().then(({data}) => {
                this.users = data;
            });
            
            let params = {
                status: 'STARTED'
            }

            await Work.list(params).then(({data}) => {
                this.works = data;
            });

            await APICalendar.list().then(({data}) => {
                this.companyEvents = data
            });

            this.loading = false
        },
        changeData(){
            this.noUsersData = this.selectedDate
        },
        changeDataByCalendar(){
            this.replacePages = true
            this.noUsersData = this.selectedDate
        },
        loadCharger(v){
            this.loading = v
        },
        functionEvents (date) {
            let color = 'red'
            
            this.monthArray.forEach(element => {
                if(element.day == date)
                    if(element.content == 'empty')
                        color = 'red'
                    else if(element.content == 'middle')
                        color = 'yellow'
                    else
                        color = 'green'
            });
            
            if(this.feriados.includes(date))
                color = 'purple'

            this.companyEvents.forEach(element => {
                if(element.day_event == date){
                    console.log("OLHA")
                    console.log(date)
                    console.log(element.day_event)
                    color = "purple"
                }
            });
            
            if(color == 'red'){

                date = new Date(date)
                if(date.getDay() == 0 || date.getDay() ==  6)
                    color = false
            }
            
            return color
        },
        searchData(event){
                if(!!event && event.type == 'submit'){
                    this.options.page = 1;
                }

                this.loading = true;

                let filter = {...this.filter};

                let request = {
                    sortBy: this.options.sortBy,
                    sortDesc: this.options.sortDesc,
                    page: this.replacePages ? 1 : this.options.page,
                    limit: this.options.itemsPerPage,
                };
                if(this.replacePages) this.replacePages = false;
                
                Object.assign(request, filter);

                localStorage["diary-free-" + this.$root.session.id] = JSON.stringify({
                    filter: this.filter,
                    options: this.options,
                });
                DiaryFree.search(request).then(response => {

                    let data = response.data;

                    this.totalfreeUsers = data.total;

                    this.freeUsers = data.data.map((item) => {

                    this.searching = false;

                    return item;
                    });

                    this.loading = false;

                });
        },
    },
    computed:{
        crumbs: function() {
            return [
            {
                text: 'Dashboard',
                disabled: false,
                to: '/',
                exact: true,
                },
                {
                text: 'Gestão interna',
                disabled: true,
                to: window.location.href,
                exact: true,
                },
            ];
        },
    }
}
</script>
<style>
    #picker .v-picker__body{
        width: 100% !important;
    }

    .v-tabs-bar {
        height: 5rem !important;
    }

    .v-expansion-panel-header {
        line-height: 3.7;
    }
</style>