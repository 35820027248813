<template>
    <section class="mt-16">  
        <div style="position:relative">  
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            class="mt-16"
            locale="pt-pt"
          >
            <template v-slot:item.article="{item}">
              {{ item.article.code }} - {{ item.article.description }}
            </template> 
            <template v-slot:item.user="{item}">
              {{ item.user.name }}
            </template> 
           
          </v-data-table>
        </div>
    </section>
  </template>
  <script>
  //import IconView from '@/components/ui/IconView.vue';
  //import IconRemove from '@/components/ui/IconRemove.vue';
  import DiaryArticles from "@/api/DiaryArticles.js";
  
  export default {
    components: {
      //IconView,
      //IconRemove
    },
    props:{
      work_id: {
        typeof: String,
        default: null
      }
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchArticles()}, 10);
        },
        deep: true,
      },
    },
    data: () => ({
      equipmentToExport: null,
      started: false,
      exportingEquipment: false,
      headers: [
        {
          text: 'Dia',
          sortable: true,
          value: 'day',
        },
        {
          text: 'Engenheiro',
          sortable: true,
          value: 'user',
        },
        {
          text: 'Artigo',
          sortable: true,
          value: 'article',
        },
        {
          text: 'Qty.',
          sortable: true,
          value: 'qty',
        },
        {
          text: 'Notas',
          sortable: true,
          value: 'obs',
        }
      ],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      speedDial:false,
      filter: {
        work_id: null
      },
    }),
    mounted(){
  
      /*if(!this.$root.session.hasPermission(["super", "articles.read"])) {
        this.$router.push('/admin');
      }*/
  
      this.fillBaseData();
    },
    methods:{
      fillBaseData() {
  
        if(localStorage["work-diary-articles-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["work-diary-articles-" + this.$root.session.id]);
  
          this.filter = data.filter;

          if(this.work_id != null)          
            this.filter.work_id = this.work_id
  
          this.options = data.options;
        }
      },
      searchArticles(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["work-diary-articles-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        DiaryArticles.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetArticles()
      {
        this.filter = {
        };
  
        this.searchArticles();
      },
      viewArticle(item){
        this.$router.push('/admin/works-settings/articles/' + item.id);
      }
    },
  };
  </script>
  