var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-16"},[(_vm.hasComponent == false)?_c('v-breadcrumbs',{staticClass:"pl-0",attrs:{"large":"","items":_vm.crumbs,"divider":"/"}}):_vm._e(),(_vm.hasComponent == false)?_c('h1',{staticClass:"primary--text"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-hard-hat")]),_vm._v(" "),_c('span',[_vm._v("Gestão de Autos de Medição")])],1):_vm._e(),_c('div',{staticStyle:{"position":"relative"}},[_c('v-data-table',{staticClass:"mt-16",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.work",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.work.code)+" - "+_vm._s(item.work.description)+" ")]}},{key:"item.invoiced",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInvoiced(item))+"% ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$currency(item.total))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('IconView',{staticClass:"mr-2",on:{"click":function($event){return _vm.viewWorkMeasurement(item)}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }