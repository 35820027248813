function currency(n) {
    let coin = '€'
   
    if(n == '')
      return '0 ' + coin
  
    if(n == null)
      return '0 ' + coin
  
    if(n == 0)
      return '0 ' + coin
    n = parseFloat(n)
  
    let currencyValue = 'EUR';
    /*if(coin == '$R')
      currencyValue = 'BRL'*/
  
    n = n.toLocaleString('en-EN', { style: 'currency', currency: currencyValue });
  
    // This is for Ios support
    // The /c/g with the replace function instead of replaceAll
    // Check more: https://stackoverflow.com/questions/2390789/how-to-replace-all-dots-in-a-string-using-javascript
  
    // Only the dot needs to have the inverted slash because its a special char (explained in the link above)
    n = n.replace(/\./g, '!')
    n = n.replace(/,/g, '.')
    n = n.replace(/!/g, ',')
    n = n.split(',00')[0]
    n = n.split('€')[1]
    return n + ' '  + coin
  }
  
  export default currency