<template>
    <v-card
        class="grow"
        color="secondary"
        outlined
        height="125px"
        dark
        elevation="0"
    >
        <v-card-subtitle>
            <div class="text-center mt-n4 mb-3">
                <span class="text-h6 font-weight-light">{{title}}</span>
            </div>
        </v-card-subtitle>
        <!-- DESKTOP -->
        <v-card-text class="d-none d-lg-block ma-0 pa-0">
        <v-row no-gutters justify="center">
            <v-col cols="12" md="auto">                            
            <v-icon
                large
                center
            >
                {{icon == null ? 'mdi-currency-eur' : icon}}
            </v-icon>
            </v-col>
            <v-col cols="12" md="auto" class="text-h6 font-weight-bold white--text ml-4">
                {{ icon == null ? $currency(custo) : $decimalPlaces(custo, 2)}}
            </v-col>
        </v-row>                      
        </v-card-text>
        <!-- MOBILE -->
        <v-card-text class="d-md-none ma-0 pa-0">
        <v-row no-gutters justify="center">
            <v-col cols="12" md="12" class="font-weight-bold white--text text-center">
                {{ icon == null ? $currency(custo) : $decimalPlaces(custo, 2)}}
            </v-col>
        </v-row>                      
        </v-card-text>
    </v-card>
</template>
<script>
export default{
    name: 'CostCard',
    props:{
        custo:[String, Number],
        title: String,
        icon: {
            typeof: String,
            default: null
        }
    }
}
</script>
<style>
.grow { 
    transition: all 0.4s ease-in-out; 
}

.grow .v-icon{
    transition: all 0.9s;
}



.grow:hover { 
    transform: scale(1.1); 
    z-index: 9999;
    background-position: -100% 100%;
    color: #F9F158 !important;
}

.grow:hover .v-icon{
    transition: all 0.95s;
    transform: rotateY(360deg);
}
</style>