import BaseApi from "./BaseApi";

export default class DiaryArticles extends BaseApi{
    construct() {}

    static async search(params) {
        return await DiaryArticles.getAxiosInstance().get(DiaryArticles.getApiUrl(),{params: params});
    }

    static async getDiariesData(params){
        return await DiaryArticles.getAxiosInstance().get(DiaryArticles.getApiUrl() + 'get-diaries-data', {params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/diaries-articles/";
    }
}