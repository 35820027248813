<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text h1">
        <v-icon color="primary">mdi-notebook</v-icon> <span>Registo Diário</span>
      </h1>

      <div class="mt-16">
        <diary method="create"></diary>
      </div>
  </section>
</template>
<script>
import Diary from '@/components/diaries/Diary'

export default {
  components: {
    Diary
  },
  beforeCreate(){
    document.title = "GPD - Registo de Partes Diárias";
  },
  data: () => ({
    
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "diaries.create"])) {
      this.$router.push('/no-permission');
    }

  },
  methods:{
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão Partes Diárias',
          disabled: false,
          to: '/admin/diaries',
          exact: true,
        },
        {
          text: 'Registo de Diário',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
