var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" Máquinas ")]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{staticClass:"d-none d-lg-block",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"primary"},[_c('tr',[_c('th',{staticClass:"text-center white--text rounded-pill rounded-r-0",staticStyle:{"width":"35%"}},[_vm._v(" Nome ")]),_c('th',{staticClass:"text-center white--text"},[_vm._v(" Código ")]),_c('th',{staticClass:"text-center white--text"},[_vm._v(" Diesel ")]),_c('th',{staticClass:"text-center white--text"},[_vm._v(" Ad Blue ")]),_c('th',{staticClass:"text-center white--text"},[_vm._v(" Horas/KM Inicio ")]),_c('th',{staticClass:"text-center white--text"},[_vm._v(" Horas/KM Fim ")]),_c('th',{staticClass:"text-center white--text"},[_vm._v(" Parado à Ordem de ")]),_c('th',{staticClass:"text-center white--text rounded-pill rounded-l-0"},[_vm._v(" Opções ")])])]),_c('tbody',_vm._l((_vm.template_fields.machines),function(machine){return _c('tr',{key:machine.id},[_c('td',{staticClass:"text-center"},[_c('validation-provider',{attrs:{"vid":"machine","name":"Máquina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{staticClass:"mt-4",attrs:{"label":"Máquina *","items":_vm.machines,"dense":"","outlined":"","item-text":function (item) { return item.code + ' - ' + item.name; },"item-value":"id","hide-selected":"","error-messages":errors},on:{"input":function($event){machine.code = $event.code; machine.id = $event.id; machine.name = $event.name; machine.started_hours = $event.next_start}},model:{value:(machine.object),callback:function ($$v) {_vm.$set(machine, "object", $$v)},expression:"machine.object"}})]}}],null,true)})],1),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(machine.code)+" ")]),_c('td',{staticClass:"text-center"},[_c('validation-provider',{attrs:{"vid":"diesel","name":"Diesel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"dense":"","outlined":"","label":"Diesel *","error-messages":errors},model:{value:(machine.diesel),callback:function ($$v) {_vm.$set(machine, "diesel", $$v)},expression:"machine.diesel"}})]}}],null,true)})],1),_c('td',{staticClass:"text-center"},[_c('validation-provider',{attrs:{"vid":"ad_blue","name":"Ad Blue","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"dense":"","outlined":"","label":"Ad Blue *","error-messages":errors},model:{value:(machine.ad_blue),callback:function ($$v) {_vm.$set(machine, "ad_blue", $$v)},expression:"machine.ad_blue"}})]}}],null,true)})],1),_c('td',{staticClass:"text-center"},[_c('validation-provider',{attrs:{"vid":"diesel","name":"Horas/KM","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"dense":"","outlined":"","label":"Horas/KM Início*","error-messages":errors},model:{value:(machine.started_hours),callback:function ($$v) {_vm.$set(machine, "started_hours", $$v)},expression:"machine.started_hours"}})]}}],null,true)})],1),_c('td',{staticClass:"text-center"},[_c('validation-provider',{attrs:{"vid":"diesel","name":"Horas/KM","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"dense":"","outlined":"","label":"Horas/KM Fim*","error-messages":errors},model:{value:(machine.finished_hours),callback:function ($$v) {_vm.$set(machine, "finished_hours", $$v)},expression:"machine.finished_hours"}})]}}],null,true)})],1),_c('td',{staticClass:"text-center"},[_c('v-text-field',{staticClass:"mt-4",attrs:{"dense":"","outlined":"","label":"Razão"},model:{value:(machine.stopped_reason),callback:function ($$v) {_vm.$set(machine, "stopped_reason", $$v)},expression:"machine.stopped_reason"}})],1),_c('td',{staticClass:"text-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"dark":"","small":"","color":"secondary"},on:{"click":function($event){_vm.error.show = true}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus-circle-multiple-outline")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"dark":"","small":"","color":"error"},on:{"click":function($event){return _vm.removeMachine(machine)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)],1)],1)])}),0)]},proxy:true}])}),_c('v-data-iterator',{staticClass:"d-block d-lg-none",attrs:{"items":_vm.template_fields.machines,"item-key":"id","items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('v-row',_vm._l((items),function(machine){return _c('v-col',{key:machine.id + '10000',attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_vm._v("Nome:")]),_c('v-list-item-content',{staticClass:"align-end"},[_c('validation-provider',{attrs:{"vid":"machine","name":"Máquina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"label":"Máquina *","items":_vm.machines,"item-text":function (item) { return item.name; },"item-value":"id","hide-selected":"","error-messages":errors},on:{"input":function($event){machine.code = $event.code; machine.id = $event.id; machine.name = $event.name}},model:{value:(machine.object),callback:function ($$v) {_vm.$set(machine, "object", $$v)},expression:"machine.object"}})]}}],null,true)})],1)],1),_c('v-list-item',[_c('v-list-item-content',[_vm._v("Código:")]),_c('v-list-item-content',{staticClass:"align-end"},[_vm._v(" "+_vm._s(machine.code)+" ")])],1),_c('v-list-item',[_c('v-list-item-content',[_vm._v("Diesel:")]),_c('v-list-item-content',{staticClass:"align-end"},[_c('validation-provider',{attrs:{"vid":"diesel","name":"Diesel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Diesel *","error-messages":errors},model:{value:(machine.diesel),callback:function ($$v) {_vm.$set(machine, "diesel", $$v)},expression:"machine.diesel"}})]}}],null,true)})],1)],1),_c('v-list-item',[_c('v-list-item-content',[_vm._v("Ad Blue:")]),_c('v-list-item-content',{staticClass:"align-end"},[_c('validation-provider',{attrs:{"vid":"ad_blue","name":"Ad Blue","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Ad Blue *","error-messages":errors},model:{value:(machine.ad_blue),callback:function ($$v) {_vm.$set(machine, "ad_blue", $$v)},expression:"machine.ad_blue"}})]}}],null,true)})],1)],1),_c('v-list-item',[_c('v-list-item-content',[_vm._v("Horas/KM Início:")]),_c('v-list-item-content',{staticClass:"align-end"},[_c('validation-provider',{attrs:{"vid":"diesel","name":"Horas/KM","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Horas/KM Início*","error-messages":errors},model:{value:(machine.started_hours),callback:function ($$v) {_vm.$set(machine, "started_hours", $$v)},expression:"machine.started_hours"}})]}}],null,true)})],1)],1),_c('v-list-item',[_c('v-list-item-content',[_vm._v("Horas/KM Início:")]),_c('v-list-item-content',{staticClass:"align-end"},[_c('validation-provider',{attrs:{"vid":"diesel","name":"Horas/KM","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Horas/KM Fim*","error-messages":errors},model:{value:(machine.finished_hours),callback:function ($$v) {_vm.$set(machine, "finished_hours", $$v)},expression:"machine.finished_hours"}})]}}],null,true)})],1)],1),_c('v-list-item',[_c('v-list-item-content',[_vm._v("Parado à Ordem de:")]),_c('v-list-item-content',{staticClass:"align-end"},[_c('v-text-field',{attrs:{"label":"Razão"},model:{value:(machine.stopped_reason),callback:function ($$v) {_vm.$set(machine, "stopped_reason", $$v)},expression:"machine.stopped_reason"}})],1)],1),(true)?_c('v-list-item',[_c('v-list-item-content',[_vm._v("Opções:")]),_c('v-list-item-content',{staticClass:"align-end"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"dark":"","small":"","block":"","color":"secondary"},on:{"click":function($event){_vm.error.show = true}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus-circle-multiple-outline")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"dark":"","small":"","block":"","color":"error"},on:{"click":function($event){return _vm.removeMachine(machine)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)}),1)]}}])}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mt-7",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":_vm.addMachine}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1)],1),_c('ErrorSnackbar',{attrs:{"message":_vm.error.message},model:{value:(_vm.error.show),callback:function ($$v) {_vm.$set(_vm.error, "show", $$v)},expression:"error.show"}}),(_vm.openConfirmationDialog)?_c('DialogConfirmation',{attrs:{"title":"Remover Item","type":"warning","opened":_vm.openConfirmationDialog},on:{"on-submit":_vm.submitRemove,"on-cancel":function($event){_vm.openConfirmationDialog = false}}},[_vm._v(" Tem a certeza que pretende remover o item? ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }