<template>
    <v-container fluid>
        <validation-observer ref="form" v-slot="{ invalid }">
            <v-row>
                <v-col cols="12">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Artigo
                                    </th>
                                    <th class="text-left">
                                        Quantidade
                                    </th>
                                    <th class="text-left">
                                        Obs.
                                    </th>
                                    <th class="text-left">
                                        Op.
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-for="artigo in fields.diary_articles" :key="artigo.id">
                                <td style="width: 50%;" class="mx-4 px-4">
                                    <validation-provider v-slot="{ errors }" vid="Artigo" name="Artigo" rules="required">
                                        <v-autocomplete
                                            v-model="artigo.article_id"
                                            :items="artigosDaObra"
                                            label="Artigo *"
                                            :error-messages="errors"
                                            dense outlined class="mt-4"
                                            :item-text="item => item.code + ' - ' + item.description"
                                            item-value="id"
                                        />
                                    </validation-provider>
                                </td>
                                <td class="mx-4 px-4" style="width: 10%;">
                                    <validation-provider v-slot="{ errors }" vid="Quantidade" name="Quantidade" rules="required">
                                        <v-text-field
                                            v-model="artigo.qty"
                                            dense outlined class="mt-4"
                                            label="Quantidade *"
                                            :error-messages="errors"
                                        />
                                    </validation-provider>
                                </td>
                                <td class="mx-4 px-4" style="width: 35%;">
                                    <validation-provider v-slot="{ errors }" vid="Quantidade" name="Quantidade" rules="|">
                                        <v-text-field
                                            v-model="artigo.obs"
                                            dense outlined class="mt-4"
                                            label="Observações"
                                            :error-messages="errors"
                                        />
                                    </validation-provider>
                                </td>
                                <td class="mx-4 px-4">
                                    <IconRemove
                                        class="mr-2"
                                        @on-submit="deleteLine(artigo)"
                                    />
                                </td>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                <v-col cols="12" align="center">
                    <v-divider></v-divider>
                    <v-btn :disabled="invalid" fab class="success mt-4" @click="addLine">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" align="center">
                    <v-btn @click="submit" :disabled="invalid" class="success">
                        Gravar
                    </v-btn>
                </v-col>
            </v-row>





            <v-btn v-if="false" @click="fillBaseData" class="success">
                asdasdasdasdsad
            </v-btn>
        </validation-observer>

        
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Artigos gravado com sucesso!
        </DialogSuccess>
    </v-container>
</template>
<script>
import DiaryAPI from '@/api/Diary.js'
import Article from '@/api/Article.js'
import dayjs from 'dayjs'
import IconRemove from '@/components/ui/IconRemove.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default{
    name: "DiaryArticle",
    components:{
        IconRemove,
        DialogSuccess
    },
    props:{
        work_id: String
    },
    data(){
        return{
            fields:{
                diary_articles: []
            },
            artigos_obra: [],
            artigos: [],
            success: false
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        onSuccess(){
            this.fillBaseData()
            this.success = false
        },
        deleteLine(item){
            let i = 0; let pos = -1;
            this.fields.diary_articles.forEach(element => {
                if(element.id == item.id)
                    pos = i 
                
                i++
            });
            

            if(pos != -1){
                this.fields.diary_articles.splice(pos, 1)
            }
        },
        addLine(){
            this.fields.diary_articles.push({
                id: dayjs().unix() + '-new',
                article_id: null,
                qty: 0, 
                obs: null
            })
        },
        fillBaseData(){
            DiaryAPI.getDiaryArticle(this.$route.params.id).then((resp)  =>  {
                if(resp.data.length > 0)
                    this.fields.diary_articles = resp.data
            })

            Article.getArtigosObra(this.work_id).then((resp) => { 
                if(resp.data != '')
                    this.artigos_obra = resp.data.artigos_obra
            });

            Article.list().then((resp)  =>  {
                this.artigos = resp.data
            })
        },
        submit(){
            DiaryAPI.setDiaryArticle(this.$route.params.id, this.fields).then(()    =>  {
                this.success = true
            })
        }
    },
    computed:{
        artigosDaObra(){
            let artigos_finais = []

            this.artigos_obra.forEach(na_obra => {
                this.artigos.forEach(artigo => {
                    if(na_obra.article_id == artigo.id)
                        artigos_finais.push(artigo)
                });
            });
            
            return artigos_finais
        }
    }
}
</script>