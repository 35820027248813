<template>
    <section class="mt-16">
        <v-breadcrumbs
            large
            :items="crumbs"
            divider="/"
            class="pl-0"
        ></v-breadcrumbs>

        <h1 class="primary--text">
            <v-icon color="primary">mdi-account-multiple</v-icon> <span>Gestão de Sistema</span>
        </h1>
        <v-container fluid v-if="!loading">
            <v-card class="elevation-2" v-if="$root.session.hasRole(['super'])">
                <v-card-title class="primary white--text">
                    Definições da aplicação
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-row>
                        <v-col cols="12" md="2">
                            <v-text-field
                                label="Preço adblue (€)"
                                v-model="fields.adblue_cost"
                                type="number"
                                dense outlined
                                @input="formatAdblueCost"
                            />
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field
                                label="Preço diesel (€)"
                                v-model="fields.diesel"
                                disabled
                                dense outlined
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="success" @click="saveConfigurationData">Gravar</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
      <SuccessSnackBar v-model="success" :message="'Dados gravados com sucesso!'" />
    </section>
</template>
<script>
import System from "@/api/System.js"
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';

export default{
    name: "Configurations",
    components:{
        SuccessSnackBar
    },
    data(){
        return{
            loading: true,
            success: false,
            fields:{
                adblue_cost: null,
                diesel: null
            }
        }
    },
    mounted(){
        if(!this.$root.session.hasPermission(["super", "system_config.read"])) {
            this.$router.push('/no-permission');
        }


        this.fillBaseData()
    },
    methods:{
        formatAdblueCost() {
            // Limita a duas casas decimais
            this.fields.adblue_cost = parseFloat(this.fields.adblue_cost).toFixed(2);
        },
        fillBaseData(){
            System.getSystemConfiguration().then((resp)   =>  {
                this.fields = resp.data
                this.loading = false
            })
        },
        saveConfigurationData(){          
            System.setSystemConfiguration(this.fields).then(()   =>  {
                this.success = true
                this.fillBaseData()
            })
        }
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
            },
            {
            text: 'Gestão de Configurações',
            disabled: true,
            to: window.location.href,
            exact: true,
            },
        ];
        }
    }
}
</script>