import BaseApi from "./BaseApi";

export default class Costs extends BaseApi{
    construct() {}
    

    static async get(params) {
        let url = Costs.getApiUrl();

        return Costs.getAxiosInstance().get(url,{params: params});
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/costs/";
    }
}