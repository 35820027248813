var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.front",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.work_front_id != null ? item.front.reference + ' - ' + item.front.description : '')+" ")]}},{key:"item.unidade_obra",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.object.code)+" - "+_vm._s(item.object.name)+" ")]}},{key:"item.unidade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.object.unit)+" ")]}},{key:"item.subempreiteiro",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subcontractor_id == null ? '-' : item.subcontractor.name)+" ")]}},{key:"item.performance",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"success"},on:{"change":function($event){return _vm.changedSwitch(item)}},model:{value:(item.enable_performance),callback:function ($$v) {_vm.$set(item, "enable_performance", $$v)},expression:"item.enable_performance"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('IconView',{on:{"click":function($event){return _vm.viewListWorkUnit(item)}}})],1),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[(_vm.$root.session.hasPermission(['super']) || _vm.$root.session.hasRole(['ceo', 'dto']))?_c('IconRemove',{staticClass:"mr-2",on:{"on-submit":function($event){return _vm.deleteWorkUnitList(item.id)}}}):_vm._e()],1)],1)]}}])}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('v-btn',{staticClass:"mt-7",attrs:{"fab":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.addNewUnit()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1)],1),_c('SuccessSnackBar',{staticClass:"mt-12",attrs:{"message":'Alteração gravada com sucesso'},model:{value:(_vm.showSuccess),callback:function ($$v) {_vm.showSuccess=$$v},expression:"showSuccess"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.showWorkList),callback:function ($$v) {_vm.showWorkList=$$v},expression:"showWorkList"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('fieldset',[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" Adicionar Unidade Obra ")]),_c('v-card-text',[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"Unidade Obra","name":"Unidade Obra","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Unidade Obra *","error-messages":errors,"dense":"","outlined":"","items":_vm.units,"item-text":function (item) { return item.name; },"item-value":"id","hide-selected":"","clearable":""},model:{value:(_vm.newWorkUnit.work_id),callback:function ($$v) {_vm.$set(_vm.newWorkUnit, "work_id", $$v)},expression:"newWorkUnit.work_id"}})]}}],null,true)})],1),(_vm.fields.work_fronts.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"Frentes Obra","name":"Frentes Obra","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.fields.work_fronts,"error-messages":errors,"label":"Frentes Obra *","dense":"","outlined":"","item-text":function (item) { return item.reference + ' - ' + item.description; },"item-value":"id"},model:{value:(_vm.newWorkUnit.work_front_id),callback:function ($$v) {_vm.$set(_vm.newWorkUnit, "work_front_id", $$v)},expression:"newWorkUnit.work_front_id"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"Código","name":"Código","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"dense":"","outlined":"","error-messages":errors,"label":"Código *","items":_vm.units,"item-text":function (item) { return item.code; },"item-value":"id","hide-selected":"","clearable":""},model:{value:(_vm.newWorkUnit.work_id),callback:function ($$v) {_vm.$set(_vm.newWorkUnit, "work_id", $$v)},expression:"newWorkUnit.work_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"Unidade","name":"Unidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"dense":"","outlined":"","error-messages":errors,"label":"Unidade","items":_vm.units,"disabled":true,"item-text":function (item) { return item.unit; },"item-value":"id","hide-selected":"","clearable":""},model:{value:(_vm.newWorkUnit.work_id),callback:function ($$v) {_vm.$set(_vm.newWorkUnit, "work_id", $$v)},expression:"newWorkUnit.work_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Calcular performance","inset":"","color":"success"},model:{value:(_vm.newWorkUnit.enable_performance),callback:function ($$v) {_vm.$set(_vm.newWorkUnit, "enable_performance", $$v)},expression:"newWorkUnit.enable_performance"}})],1),_c('v-divider',{staticClass:"mt-4 mb-4"})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"Qtd. Orç.","name":"Qtd. Orç.","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","error-messages":errors,"prepend-inner-icon":"mdi-form-textbox","label":"Qtd. Orç. *","clearable":""},model:{value:(_vm.newWorkUnit.qty_budgeted),callback:function ($$v) {_vm.$set(_vm.newWorkUnit, "qty_budgeted", $$v)},expression:"newWorkUnit.qty_budgeted"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"Rend. Orç.","name":"Rend. Orç.","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","error-messages":errors,"small":"","prepend-inner-icon":"mdi-google-circles-communities","label":"Rend. Orç. *","clearable":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',[_vm._v(_vm._s(_vm.newWorkUnit.unit_measure))])]},proxy:true}],null,true),model:{value:(_vm.newWorkUnit.budgeted_income),callback:function ($$v) {_vm.$set(_vm.newWorkUnit, "budgeted_income", $$v)},expression:"newWorkUnit.budgeted_income"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","prepend-inner-icon":"mdi-counter","label":"Equipa","clearable":""},model:{value:(_vm.newWorkUnit.team),callback:function ($$v) {_vm.$set(_vm.newWorkUnit, "team", $$v)},expression:"newWorkUnit.team"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.subcontractors,"dense":"","outlined":"","item-text":"name","item-value":"id","clearable":"","label":"Subempreiteiros"},model:{value:(_vm.newWorkUnit.subcontractor_id),callback:function ($$v) {_vm.$set(_vm.newWorkUnit, "subcontractor_id", $$v)},expression:"newWorkUnit.subcontractor_id"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":_vm.closeWorkList}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"disabled":invalid,"depressed":"","color":"primary"},on:{"click":_vm.addWorkUnitToWork}},[_vm._v(" Gravar ")])],1)],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }