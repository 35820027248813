import BaseApi from "./BaseApi";

export default class WorkMeasurements extends BaseApi{
    construct() {}
    
    static async list(params) {
        let url = WorkMeasurements.getApiUrl() + "list";

        return WorkMeasurements.getAxiosInstance().get(url,{params: params});
    }

    static async create(params) {
        return await WorkMeasurements.getAxiosInstance().post(WorkMeasurements.getApiUrl(),params);
    }

    static async search(params) {
        return await WorkMeasurements.getAxiosInstance().get(WorkMeasurements.getApiUrl(),{params: params});
    }
    
    static async update(params, id) {
        return await WorkMeasurements.getAxiosInstance().put(WorkMeasurements.getApiUrl() + id,params);
    }

    static async delete(id) {
        return await WorkMeasurements.getAxiosInstance().delete(WorkMeasurements.getApiUrl() + id);
    }

    static async find(id) {
        return await WorkMeasurements.getAxiosInstance().get(WorkMeasurements.getApiUrl() + id);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/work-measurements/";
    }
    
}