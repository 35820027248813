var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Artigo ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Quantidade ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Obs. ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Op. ")])])]),_vm._l((_vm.fields.diary_articles),function(artigo){return _c('tbody',{key:artigo.id},[_c('td',{staticClass:"mx-4 px-4",staticStyle:{"width":"50%"}},[_c('validation-provider',{attrs:{"vid":"Artigo","name":"Artigo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-4",attrs:{"items":_vm.artigosDaObra,"label":"Artigo *","error-messages":errors,"dense":"","outlined":"","item-text":function (item) { return item.code + ' - ' + item.description; },"item-value":"id"},model:{value:(artigo.article_id),callback:function ($$v) {_vm.$set(artigo, "article_id", $$v)},expression:"artigo.article_id"}})]}}],null,true)})],1),_c('td',{staticClass:"mx-4 px-4",staticStyle:{"width":"10%"}},[_c('validation-provider',{attrs:{"vid":"Quantidade","name":"Quantidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"dense":"","outlined":"","label":"Quantidade *","error-messages":errors},model:{value:(artigo.qty),callback:function ($$v) {_vm.$set(artigo, "qty", $$v)},expression:"artigo.qty"}})]}}],null,true)})],1),_c('td',{staticClass:"mx-4 px-4",staticStyle:{"width":"35%"}},[_c('validation-provider',{attrs:{"vid":"Quantidade","name":"Quantidade","rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"dense":"","outlined":"","label":"Observações","error-messages":errors},model:{value:(artigo.obs),callback:function ($$v) {_vm.$set(artigo, "obs", $$v)},expression:"artigo.obs"}})]}}],null,true)})],1),_c('td',{staticClass:"mx-4 px-4"},[_c('IconRemove',{staticClass:"mr-2",on:{"on-submit":function($event){return _vm.deleteLine(artigo)}}})],1)])})]},proxy:true}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-divider'),_c('v-btn',{staticClass:"success mt-4",attrs:{"disabled":invalid,"fab":""},on:{"click":_vm.addLine}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-btn',{staticClass:"success",attrs:{"disabled":invalid},on:{"click":_vm.submit}},[_vm._v(" Gravar ")])],1)],1),(false)?_c('v-btn',{staticClass:"success",on:{"click":_vm.fillBaseData}},[_vm._v(" asdasdasdasdsad ")]):_vm._e()]}}])}),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" Artigos gravado com sucesso! ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }