var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"elevation-0",attrs:{"outlined":"","height":"200px"}},[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" Faturado (%) ")]),_c('v-card-text',{staticClass:"text-center mt-4"},[_c('v-progress-circular',{attrs:{"rotate":360,"size":100,"width":15,"value":_vm.work.invoiced,"color":"teal"}},[_vm._v(" "+_vm._s(_vm.work.invoiced)+"% ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{staticClass:"elevation-0",attrs:{"outlined":"","height":"200px"}},[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" Estado "),_c('v-spacer'),_c('v-btn',{staticClass:"success",attrs:{"to":'/admin/works-settings/work-measurements/create/' + _vm.$route.params.id}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Auto de Medição ")],1)],1),_c('v-card-text',{staticClass:"mt-4"},[_vm._v(" Sem informação ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{attrs:{"background-color":"primary","dark":"","fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Artigos")]),_c('v-tab',[_vm._v("Dados da Obra")]),_c('v-tab',[_vm._v("Autos")])],1),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:1},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Artigo ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Quantidade ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Preço Uni. (€) ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Total (€) ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Op. ")])])]),_vm._l((_vm.fields.artigos_obra),function(artigo){return _c('tbody',{key:artigo.id},[_c('td',{staticClass:"mx-4 px-4",staticStyle:{"width":"50%"}},[_c('validation-provider',{attrs:{"vid":"Artigo","name":"Artigo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-4",attrs:{"items":_vm.artigos,"label":"Artigo *","error-messages":errors,"dense":"","outlined":"","item-text":function (item) { return item.code + ' - ' + item.description; },"item-value":"id"},model:{value:(artigo.article_id),callback:function ($$v) {_vm.$set(artigo, "article_id", $$v)},expression:"artigo.article_id"}})]}}],null,true)})],1),_c('td',{staticClass:"mx-4 px-4"},[_c('validation-provider',{attrs:{"vid":"Quantidade","name":"Quantidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"dense":"","outlined":"","label":"Quantidade *","error-messages":errors},model:{value:(artigo.qty),callback:function ($$v) {_vm.$set(artigo, "qty", $$v)},expression:"artigo.qty"}})]}}],null,true)})],1),_c('td',{staticClass:"mx-4 px-4"},[_c('validation-provider',{attrs:{"vid":"Preço","name":"Preço","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"value":_vm.getItemValue(artigo),"dense":"","outlined":"","readonly":"","error-messages":errors,"label":"Preço (€)"}})]}}],null,true)})],1),_c('td',{staticClass:"mx-4 px-4"},[_c('validation-provider',{attrs:{"vid":"Total","name":"Total","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"value":_vm.getItemTotalValue(artigo),"readonly":"","error-messages":errors,"dense":"","outlined":"","label":"Total (€)"}})]}}],null,true)})],1),_c('td',{staticClass:"mx-4 px-4"},[_c('IconRemove',{staticClass:"mr-2",on:{"on-submit":function($event){return _vm.deleteLine(artigo)}}})],1)])})]},proxy:true}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-divider'),_c('v-btn',{staticClass:"success mt-4",attrs:{"disabled":invalid,"fab":""},on:{"click":_vm.addLine}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-btn',{staticClass:"success",attrs:{"disabled":_vm.fields.artigos_obra.length == 0 || invalid},on:{"click":_vm.submit}},[_vm._v(" Gravar ")])],1)],1)]}}])})],1),_c('v-tab-item',{key:2},[_c('DiaryArticles',{attrs:{"work_id":_vm.$route.params.id}})],1),_c('v-tab-item',{key:3},[_c('WorksMeasurements',{attrs:{"hasComponent":true,"work_id":_vm.$route.params.id}})],1)],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" Artigo gravado com sucesso! ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }