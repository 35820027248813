<template>
    <section class="mt-16">
        <v-breadcrumbs
            large
            :items="crumbs"
            divider="/"
            class="pl-0"
        ></v-breadcrumbs>

        <h1 class="primary--text">
            <v-icon color="primary">mdi-account-multiple</v-icon> <span>Gestão de Sistema</span>
        </h1>
        <v-container fluid v-if="!loading">

            <v-card class="elevation-2 mt-2" v-if="$root.session.hasRole(['super'])">
                <v-card-title class="primary white--text">
                    Super Admin Tools
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-row>
                        <v-col cols="12">
                            <v-switch
                                v-model="status"
                                color="error"
                                label="Ativar modo manutenação"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="success" @click="saveSystemConfig">Gravar</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
      <SuccessSnackBar v-model="success" :message="'Dados gravados com sucesso!'" />
    </section>
</template>
<script>
import System from "@/api/System.js"
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';

export default{
    name: "System",
    components:{
        SuccessSnackBar
    },
    data(){
        return{
            loading: true,
            status: null,
            success: false,
        }
    },
    mounted(){        
        if(!this.$root.session.hasPermission(["super"])) {
            this.$router.push('/no-permission');
        }

        this.fillBaseData()
    },
    methods:{
        fillBaseData(){
            System.getStatus().then((resp)  =>  {
                this.status = resp.data.value == "0" ? false : true

                this.loading = false
            })
        },
        saveSystemConfig(){
            this.loading = true
            let data = {
                maintenance: this.status
            }
            System.setStatus(data).then(()  =>  {
                this.loading = false

                window.location.reload();
            })
        },
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
            },
            {
            text: 'Gestão de Sistema',
            disabled: true,
            to: window.location.href,
            exact: true,
            },
        ];
        }
    }
}
</script>